import React, { Component } from "react";
import { Text } from "react-easy-i18n";
import Validation from "../../../common/validation";
import localise from "../../../common/localise";
import NextButton from "../../nextButton/nextButton";
import PropTypes from "prop-types";

import "./index.scss";

export default class SetupForm extends Component {
  constructor(props) {
    super(props);
    let formErrors = {};
    const data = props.data ? props.data : {};

    // get the field names

    Object.keys(data).forEach((key, index) => {
      formErrors[key] = null;
    });
    this.state = { data, formErrors };
  }

  onNext() {
    let { data } = this.state;
    let ok = true;

    // validate all the fields

    Object.keys(data).forEach((key, index) => {
      const res = this.validateField(key, data[key]);

      if (!res) ok = false;
    });

    if (ok && this.props.onNext) this.props.onNext(data);
  }

  validateField(id, value) {
    let { data, formErrors } = this.state;

    formErrors[id] = null;

    switch (id) {
      case "email":
        // validate email

        if (!Validation.validateEmail(value))
          formErrors[id] = <Text text="setup.form.invalidEmail" />;

        break;

      case "confirmEmail":
        if (data.email !== value)
          formErrors[id] = <Text text="setup.form.confirmEmailErrorMessage" />;

        break;

        case "Phone":
          
          if (data.Phonetype !== "0" && value === "" )
              formErrors["Phone"] = <Text text="setup.form.PhoneRequiredErrorMessage" /> ;
          
          if (this.phonenumbervalidate(value) )
              formErrors["Phone"] = <Text text="setup.form.invalidPhonenumber" /> ;
          break;



          case "Phonetype":
            if (value !== "0" && data.Phone === "" )
                formErrors["Phone"] = <Text text="setup.form.PhoneRequiredErrorMessage" /> ;
            else 
              if (this.phonenumbervalidate(data.Phone) )
                formErrors["Phone"] = <Text text="setup.form.invalidPhonenumber" /> ;
              else
                formErrors["Phone"] = null;
            
                
            break;
          case "bestDescription":
            formErrors[id] =
            value === "" || value === 0 ? (
              <Text text="setup.form.bestDescriptionMessage" />
            ) : null;
          
              
            break;

          case "postcode":
            if (this.postalCodeValidate(value, data.country)) {
              formErrors["postcode"] = <Text text="setup.form.postcodeRequiredMessage" /> ;
            } else if (value === "" ) {
                // Check if optional
                if(data.country === "Ireland") {
                    formErrors["postcode"] = null;
                } else {
                    formErrors["postcode"] = <Text text="setup.form.noValue" />
                }
            } else {
              formErrors["postcode"] = null;
            }

            break;

      default:
        // all text fields are mandatory
        if(id !=="Phone")
        {
        formErrors[id] =
          value === "" || value === 0 ? (
            <Text text="setup.form.noValue" />
          ) : null;
        }
        break;
    }

    // change the data

    data[id] = value;

    this.setState({ data, formErrors });

    return formErrors[id] === null;
  }

  phonenumbervalidate(inputtxt)
  {
    var phonenoregex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(!phonenoregex.test(inputtxt) && inputtxt.length > 0)
      {
      return true;      
    }
    else
      {
      return false;
      }
  }

  postalCodeValidate (value, country)  {
    let regEx;
    switch (country) {
      case "Canada":
          regEx = /^[A-z][0-9][A-z][\s]?[0-9][A-z][0-9]$/;
          if(!regEx.test(value) && value.length > 0) {
            return true;      
          }
          return false;
      case "United Kingdom":
          regEx = /^[A-Za-z]{1,2}\d(\d|[A-Za-z])?\s?\d[A-Za-z]{2}$/;
          if(!regEx.test(value) && value.length > 0) {
            return true;      
          }
          return false;    
      default:
          return false;

    }

  }
  renderTextField(id, value, label, helpText, autofocus, disabled) {
    const { formErrors } = this.state;

    const errorMsg = formErrors[id];

    return (
      <div className={`form-group ${errorMsg ? "has-error" : ""}`}>
        <label className="control-label" htmlFor={id}>
          {label ? label : <span>&nbsp;</span>}

          {helpText ? (
            <span className="field-info-text">{helpText}</span>
          ) : null}
        </label>

        <input
          type="text"
          className="form-control"
          disabled={disabled}
          id={id}
          onChange={this.onTextChange.bind(this)}
          value={value}
          aria-label={label}
          autoFocus={autofocus}
        />

        {errorMsg ? (
          <span className="error-msg" role="alert">
            {errorMsg}
          </span>
        ) : null}
      </div>
    );
  }

  renderEmailField(id, value, label, helpText) {
    const { formErrors } = this.state;

    const errorMsg = formErrors[id];

    return (
      <div className={`form-group ${errorMsg ? "has-error" : ""}`}>
        <label className="control-label" htmlFor={id}>
          {label ? label : <span>&nbsp;</span>}

          {helpText ? (
            <span className="field-info-text">{helpText}</span>
          ) : null}
        </label>

        <input
          type="email"
          className="form-control"
          id={id}
          onChange={this.onTextChange.bind(this)}
          value={value}
          aria-label={label}
        />

        {errorMsg ? (
          <span className="error-msg " role="alert">
            {errorMsg}
          </span>
        ) : null}
      </div>
    );
  }

  renderSelectField(id, label, options, selected, helpText) {
    const { formErrors } = this.state;

    const errorMsg = formErrors[id];

    return (
      <div className={`form-group ${errorMsg ? "has-error" : ""}`}>
        <label className="control-label">
          {label ? label : <span>&nbsp;</span>}

          {helpText ? (
            <span className="field-info-text">{helpText}</span>
          ) : null}
        </label>

        <select
          className="form-control"
          id={id}
          value={selected}
          onChange={this.onSelectChange.bind(this)}
        >

        {this.getOptions(options,id)}
        </select>

        {errorMsg ? (
          <span className="error-msg" role="alert">
            {errorMsg}
          </span>
        ) : null}
      </div>
    );
  }

  renderRadioField(id, label, options, selected, helpText) {
    const { formErrors } = this.state;

    const errorMsg = formErrors[id];

    return (
      <div className={`form-group ${errorMsg ? "has-error" : ""}`}>
        <label className="control-label" htmlFor={id}>
          {label ? label : <span>&nbsp;</span>}

          {helpText ? (
            <span className="field-info-text">{helpText}</span>
          ) : null}
        </label>

        {this.getRadioGroup(options,id)}

        {errorMsg ? (
          <span className="error-msg" role="alert">
            {errorMsg}
          </span>
        ) : null}
      </div>
    );
  }

  getRadioGroup(options, id) {
    const sanitise = option => option.replace(/ /gi, '-').toLowerCase();
    return (
      options ? 
        options.map(x => (
          <label htmlFor={`${id}-${sanitise(x.option)}`} key={`${id}-${sanitise(x.option)}`} class="radio-control control-label">
            <Text text={x.option} />
            <input 
              type="radio" 
              id={`${id}-${sanitise(x.option)}`} 
              name={id} 
              value={x.value}
              onChange={this.onRadioChange.bind(this)}
            />
            <span class="radioPoint"></span>
          </label>
      ))
    : null)
  }

  getOptions(options,id)
  {
    let i = 0;
    if(id === "Phonetype")
    return(
    options
      ? options.map(x => (
          <option key={x} value={i++}>
            {x}
          </option>
        ))
      : null)
    else
    return(options
      ? options.map(x => (
          <option key={typeof x.option === "undefined" ? x: x.option} value={typeof x.value === "undefined" ? x : x.value}>
            {typeof x.option === "undefined" ? x : x.option}
          </option>
        ))
      : null)


  }
  renderphonenumber(phone,phonetype)
  {

    if(localise.getString("setup.form.enablephone") === "true")
    return(
    <div className="form-field">
      <div className="input-field">
              {this.renderSelectField(
                "Phonetype",
                <Text text="setup.form.phonetypeText" />,
                localise.getString("setup.form.phonetype"),
                phonetype,
                "",

              )}
      </div>
      <div className="input-field">
        {this.renderTextField(
          "Phone",
          phone,
          <Text text={localise.getString("setup.form.phone")} />, 
          <Text text="setup.form.phoneNumberMessage" />,
        )}
      </div>

    </div>)


  }
  renderCheckBox(id, checked, helpText) {
    const { formErrors } = this.state;

    const errorMsg = formErrors[id];

    return (
      <div className={`form-group ${errorMsg ? "has-error" : ""}`}>
        <label className="big-checkbox">
          <div>
            <input
              id={id}
              name={id}
              type="checkbox"
              checked={checked}
              onChange={this.onCheckboxChange.bind(this)}
            />
          </div>

          {helpText}
        </label>

        {errorMsg ? (
          <span className="error-msg" role="alert">
            {errorMsg}
          </span>
        ) : null}
      </div>
    );
  }

  onTextChange(e) {
    e.preventDefault();
    if (!e) return;

    const id = e.target.id;
    const val = e.target.value;

    this.validateField(id, val);
  }

  onSelectChange(e) {

    if (!e) return;

    const id = e.target.id;
    const val = e.target.value;

    this.validateField(id, val);
  }

  onRadioChange(e) {
    if (!e) return;

    const id = e.target.name;
    const val = e.target.checked ? e.target.value : '';

    this.validateField(id, val);
  }

  onCheckboxChange(e) {
    if (!e) return;

    const id = e.target.name;
    const val = e.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        [id]:!prevState.data[id],
      }
    })
    );
    this.validateField(id, val);
  }

  render() {
    const { data } = this.state;

    const t = "setup.form";

    const privacyPolicyText = localise.getString(t + ".t4");

    return (
      <div className="step form">
        <p>
          <Text text={`${t}.t1`} />
        </p>

        <h1>
          <Text text={`${t}.t2`} />
        </h1>

        <section aria-label="fields">
          <div className="form-field">
            <div className="input-field">
              {this.renderTextField(
                "firstName",
                data.firstName,
                <Text text={`${t}.firstName`} />,
                null,
                true
              )}
            </div>

            <div className="input-field">
              {this.renderTextField(
                "lastName",
                data.lastName,
                <Text text={`${t}.lastName`} />
              )}
            </div>
          </div>

          <div className="form-field">
            <div className="input-field full-width">
              {this.renderEmailField(
                "email",
                data.email,
                <Text text={`${t}.email`} />,
                <Text text={`${t}.emailMessage`} />
              )}
            </div>
          </div>
          
          <div className="form-field">
            <div className="input-field">
              {this.renderTextField(
                "postcode",
                data.postcode,
                <Text text={`${t}.postcode`} />,
                <Text text={`${t}.postcodeMessage`} />
              )}
            </div>

            <div className="input-field">
              {this.renderSelectField(
                "country",
                <Text text={`${t}.country`} />,
                localise.getString(t+".countryList"),
                data.country,
                <Text text={`${t}.countryMessage`} />,

              )}
            </div>
          </div>
         
          {this.renderphonenumber(data.Phone,data.Phonetype)}

          {localise.getString(t+'.enableBestDescription') === "true" && 
          <div className="form=field">
            <div className="input-field">
              {this.renderRadioField(
                "bestDescription",
                <Text text={`${t}.bestDescription`} />,
                localise.getString(t+".bestDescriptionList"),
                '',
                '',

              )}
            </div>
          </div>
        }


        </section>
        

        <section aria-label="fields">
          <article className="privacy-policy">
            <Text text={`${t}.t3`} />

            <span>
            {(data.country === "Canada" || 
              data.country === "United Kingdom" ||
              data.country === "Ireland") && 
            <div className="input-field">
              {this.renderCheckBox(
                "marketingConsent",
                data.marketingConsent,
                <Text text={`${t}.marketingConsent`} />
              )}
            </div>
            }
              {privacyPolicyText}

              <a
                className="btn btn-link"
                href={localise.getString("appSettings.privacyPolicy")}
                role="button"
                target="_blank"
                rel="noopener noreferrer"
                title={localise.getString(`${t}.privacyPolicy`)}
              >
                <Text text={`${t}.privacyPolicy`} />
              </a>
            </span>
          </article>

          <NextButton
            label={t + ".nextButton"}
            onClick={this.onNext.bind(this)}
          ></NextButton>
        </section>
      </div>
    );
  }
}

SetupForm.propTypes = {
  data: PropTypes.object,
  onNext: PropTypes.func
};
