import React, { Component } from 'react'
import { Text } from 'react-easy-i18n'
import localise from '../../../common/localise'
import NextButton from '../../nextButton/nextButton'
import PropTypes from 'prop-types'
import config from '../../../common/config'

import './index.scss';

export default class SetupAgeRange extends Component {

  constructor(props) {

    super(props)
    const selectText = localise.getString('setup.ageRange.select')
    this.state = { selection: selectText }
  }

  onNext() {

    const { selection } = this.state

    if (selection && this.props.onNext)
      this.props.onNext(selection)
  }

  onSelectChange(e) {

      //const id = e.target.id
      const val = e.target.value

      this.setState({selection: val})
    }

    render() {

      const { selection } = this.state
      const t = 'setup.ageRange'
      const selectText = localise.getString(t + '.select')
      const yearsText = localise.getString(t + '.years')
      
      let ageRanges = config.ageRanges

      let options = [selectText]

      ageRanges.forEach(x => { options.push(x) })
        
      return (
        <div className="step age-range">
        <p><Text text={`${t}.t1`} /></p>
        <h1><Text text={`${t}.t2`} /></h1>
        <div className='form-group'>
      {/* autoFocus={true} */}
      <select className="form-control" onChange={this.onSelectChange.bind(this)} >
      {
        options.map((x,i) => <option key={x} value={x} >{x + (i !== 0 ? ' ' + yearsText : '')}</option> )
      }
      </select>
      </div>
      <section>
      {
        selection === selectText?
        null
        :
        <NextButton label={t + ".nextButton"} onClick={this.onNext.bind(this)}></NextButton>
      }
      </section>
      </div>
      )
    }
  }

  SetupAgeRange.propTypes = {
    onNext: PropTypes.func
  }