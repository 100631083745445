import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app/App";
//import * as serviceWorker from './serviceWorker';
// import TagManager from "react-gtm-module";
import "babel-polyfill"; // for IE11 support
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "./index.scss";

// const tagManagerArgs = {
//   gtmId: "GTM-P8RS2XM"
// };

// setTimeout(function() {
//   TagManager.initialize(tagManagerArgs);
// }, 3000);

ReactDOM.render(
  <Router>
    <Switch>
      {/* <App /> */}
      {/* Redirect all app requests to AU to disable localisation */}
      <Route exact path="/au" component={App} />
      <Route exact path="/uk" component={App} />
      <Route exact path="/us" component={App} />
      <Route exact path="/attune" render={() => <Redirect to="/au?utm_source=clinic_website&utm_medium=referral&utm_content=banner_ad&utm_campaign=Attune" />} />
      <Route exact path="/us/letter" render={() => <Redirect to="/us?utm_source=henrywurst&utm_medium=directmail&utm_campaign=en-r_ci_can_bau_reengage&utm_content=dtc_us_hac_letter" />} />
      <Route exact path="*" render={() => <Redirect to="/au" />} />

      {/* <Route path="/:crap?/:country?" component={App} />
      <Route path="/:country?" component={App} /> */}
    </Switch>
  </Router>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
