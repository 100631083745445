import React, { Component } from "react";
import { Text } from "react-easy-i18n";
import PropTypes from "prop-types";

import config from "../../../common/config";
import localise from "../../../common/localise";

import "./candidate.scss";

export default class ResultCandidate extends Component {
  onNext(id) {
    if (this.props.onNext) this.props.onNext(id);
  }

  onRepeatTest() {
    if (this.props.onRepeatTest) this.props.onRepeatTest();
  }

  componentDidMount() {
    // const btn = this.refs.btn
    // if (btn)
    //     btn.focus()
  }
  buildfactslink()
  {
    const {country} = this.props.data;
    const link = country !== "Canada" ? localise.getString("appSettings.getthefacts") : localise.getString("appSettings.getthefactsAlt")
    if(localise.getString("appSettings.region") === "CAM")
    return(
      <a
      ref="btn"
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      className="btn btn-link btn-more"
      title={localise.getString("result.candidate.discoverMore.btn1")}
      onClick={this.onNext.bind(this, "Frequently Asked Questions")}
    >
      <Text text={"result.candidate.discoverMore.btn1"} />
    </a>
)

else
return(
  <a
  ref="btn"
  href={localise.getString("appSettings.getthefacts")}
  rel="noopener noreferrer"
  target="_blank"
  className="btn btn-link btn-more"
  title={localise.getString("result.candidate.discoverMore.btn1")}
  onClick={this.onNext.bind(this, "More Information")}
>
  <Text text={"result.candidate.discoverMore.btn1"} />
</a>
)


  }
  render() {
    const t = "result.candidate";
    const country1 = localise.getString("appSettings.country1");
    const phone1 = localise.getString("appSettings.phone1");
    const phoneTelLink1 = phone1.replace(/\s+/g, ""); //Remove white spaces from phone number
    const country2 = localise.getString("appSettings.country2");
    const phone2 = localise.getString("appSettings.phone2");
    const phoneTelLink2 = phone2.replace(/\s+/g, ""); //Remove white spaces from phone number
    const email = localise.getString("appSettings.email");
    const emailSubject = localise.getString("appSettings.emailSubject");

    return (
      <section className="step candidate" aria-label="region">
        <section className="top" aria-label="top section">
          <section className="panel" aria-label="panel">
            <div className="content">
              <div>
                <h2>
                  <Text className="t1" text={`${t}.t1`} />
                </h2>
              </div>

              <p>
                <Text text={`${t}.t4`} />
              </p>
            </div>
          </section>
        </section>

        <section className="bottom row" aria-label="bottom section">
          <section className="col-md-7" aria-label="column">
            <article className="panel panel-border">
              <h3>
                <Text className="t1 bold" text={`${t}.speakToSomeone.t1`} />
              </h3>

              <p>
                {" "}
                <Text className="t2" text={`${t}.speakToSomeone.t2`} />
              </p>

              <p>
                <Text className="t3" text={`${t}.speakToSomeone.t3`} />
              </p>

              <article className="links">
                <article>
                  <a
                    href={`mailto:${email}?subject=${emailSubject}`}
                    onClick={this.onNext.bind(this, "Contact Concierge")}
                    className="btn btn-next"
                  >
                    {email}
                    <span className="arrow"></span>
                  </a>
                </article>

                <article className="mar-t-10 speakdiv">
                  <img
                    className="icon small"
                    src={config.getImage("Icon_phone.svg")}
                    alt="phone"
                  />
                  {/* <Text text={`${t}.speakToSomeone.call`} className="call" /> */}
                  <div>
                    <a
                      href={`tel:${phoneTelLink1}`}
                      onClick={this.onNext.bind(this, "Contact Concierge")}
                      className="btn btn-link btn-phone"
                    >
                      {phone1}
                    </a>
                    {country1} &nbsp;  
                    <a
                      href={`tel:${phoneTelLink2}`}
                      onClick={this.onNext.bind(this, "Contact Concierge")}
                      className="btn btn-link btn-phone"
                    >
                      {phone2}
                    </a>
                    {country2}
                    <div>
                      <Text
                        text={`${t}.speakToSomeone.calltext`}
                        className="smallfont"
                      />
                    </div>
                  </div>
                </article>
              </article>
            </article>
          </section>

          <section className="col-md-5" aria-label="column">
            <article className="panel panel-border">
              <h3>
                {" "}
                <Text className="t1 bold" text={`${t}.discoverMore.t1`} />
              </h3>

              <p>
                {" "}
                <Text className="smallfont" text={`${t}.discoverMore.t2`} />
                <sup>1</sup>{" "}
              </p>
              <p>
                {" "}
                <Text text={`${t}.discoverMore.t3`} />
              </p>

              {this.buildfactslink()}
            </article>

            <article className="panel  panel-border">
              {/* autoFocus={true}  */}

              <button
                className="btn btn-link btn-repeat"
                onClick={this.onRepeatTest.bind(this)}
                title={localise.getString(`${t}.takeTheTestAgain`)}
              >
                <Text text={`${t}.takeTheTestAgain`} />
              </button>
            </article>
          </section>
        </section>

        <section className="disclaimer" aria-label="disclaimer">
          <p>
            <Text className="t2 smallfont bold" text={`${t}.disclaimer.t1`} />
            <br />
            <Text className="t2 smallfont" text={`${t}.disclaimer.t2`} />
            <br />
            <Text className="t2 smallfont" text={`${t}.disclaimer.t3`} />
            <br />
            <Text className="t2 smallfont" text={`${t}.disclaimer.t4`} />
          </p>
        </section>
      </section>
    );
  }
}

ResultCandidate.propTypes = {
  onNext: PropTypes.func,
  onRepeatTest: PropTypes.func
};
