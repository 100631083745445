import config from '../common/config'
import 'isomorphic-fetch'

export default class cochlearAPI {

  static setup() {

    const url = config.apiURL + 'setup'

    const functionName = 'cochlearAPI.setup: '

    return fetch(url)
      .then(res => res.json())
      .then(res => {

        let err = null

        if (!res || !res.practiceFiles || !res.practiceFiles.audioFiles)
          err = functionName + 'invalid setup practice data '

        if (!res || !res.actualTestFiles || !res.actualTestFiles.audioFiles)
          err = functionName + 'invalid setup test data '

        if (err)
          throw (err)

        return res
      })
      .catch(err => {

        const msg = 'cochlearAPI.setup: error: ' + err
        console.log(msg)

        throw msg
      });
  }

  static score(data) {

    var url = config.apiURL + 'score';

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(response => {
        //const res = JSON.stringify(response)
        //console.log('Success:', res)

        return response
      })
    //.catch(error => console.error('Error:', error));
  }
}