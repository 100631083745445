import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import config from '../../common/config'

import './loading.scss';

export default class Loading extends Component {
	render() {
		return <div className='overlay'>
		<div className="loading">
		<img src={config.getImage('ajax-loader.gif')} alt='loading' />
		<Text text='loading' />
		</div>
		</div>
	}
}
