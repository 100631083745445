import React, { Component } from 'react';
import ChooseSound from './chooseSound/chooseSound'
import TestBreather from './breather/breather'
import TestPause from './pause/pause'
import TestStop from './stop/stop'
import TestIntro from './intro/intro'
import TestUnsure from './unsure/unsure'
import config from '../../common/config'
import PropTypes from 'prop-types'

import './test.scss'

export default class Test extends Component {

  constructor(props) {

    super(props)

    this.state = {step: 'intro', repeats: 0, canPlay: true, feedbackCount: 0, currentTime: 0}

    // show unsure screen if the user clicked unsure n times in practice

    if (props.unsure >= config.SHOW_UNSURE_SCREEN_CLICK_NO)
      this.state.step = 'unsure'
  }

  componentDidMount() {
    this.setState({currentQuestionRepeatCount: 0, canPlay: true, currentTime: 0})
  }

  componentDidUpdate(prevProps) {

    const { question } = this.props
    const { feedbackCount } = this.state

      if (question !== prevProps.question) {

        let nextState = {currentQuestionRepeatCount: 0, canPlay: true, feedbackCount, currentTime: 0 }

        if ( (question === config.breatherQuestion && feedbackCount === 0) ||
             (question === config.almostThereQuestion1 && feedbackCount === 1) || 
             (question === config.almostThereQuestion2 && feedbackCount === 2) )
        {
          nextState.step = 'breather'
          nextState.feedbackCount++
        }

        this.setState(nextState)
      }
  }

  onNext(selection, t) {

    let { step, currentQuestionRepeatCount } = this.state
    const { question } = this.props

    switch ( step ) {
      case 'intro':
          this.setState({step: 'test', currentTime: 0})
          break;
      case 'test':
      default: 
          if (this.props.onSubmit) {
            this.setState({currentTime: 0})
            this.props.onSubmit(question, selection, t, currentQuestionRepeatCount)
          }
            
        break;
      case 'breather': 
      case 'stop':
      case 'pause': 
        this.setState({step: 'test'})
        break
      case "unsure":
        this.setState({step: 'intro'})
        break
    }

    // set the progress bar position
    // if (this.props.onProgress)
    //   this.props.onProgress(stepn * (1.0 / this.TOTAL_STEPS))
  }

  onRepeat() {
    const { repeats, currentQuestionRepeatCount } = this.state

    if (repeats < config.maxRepeats)
      this.setState({repeats: repeats+1, currentQuestionRepeatCount: currentQuestionRepeatCount+1, canPlay: true, currentTime: 0})
  }

  onRepeatPractice() {
    if (this.props.onRepeatPractice)
      this.props.onRepeatPractice()
  }

  onStop() {

    // set currentTime to some impossibly high value so pause-continue cant replay the sounds
    
    this.setState({step: 'stop', canPlay: false, currentTime: 100})
  }

  onPause(currentTime) {
    this.setState({step: 'pause', canPlay: true, currentTime})
  }

  getContent() {

    const { step, repeats, canPlay, currentTime } = this.state
    const { data, setup, question } = this.props
    let content

    switch (step) {
      case 'intro':
      default:
          content = <TestIntro onNext={this.onNext.bind(this)} data={data} altContent={this.props.unsure >= config.SHOW_UNSURE_SCREEN_CLICK_NO} />
          break
      case 'unsure':
          content = <TestUnsure onNext={this.onNext.bind(this)} data={data} onRepeatPractice={this.onRepeatPractice.bind(this)} />
          break
      case 'test': 
      
          content = <ChooseSound files={setup.actualTestFiles}
              question={question} 
              setup={setup} 
              data={data} 
              repeats={repeats}
              onNext={this.onNext.bind(this)} 
              onRepeat={this.onRepeat.bind(this)} 
              onStop={this.onStop.bind(this)}
              onPause={this.onPause.bind(this)}
              canPlay={canPlay}
              currentTime={currentTime}
              />
            break

      case 'breather': 

          content = <TestBreather question={question} onNext={this.onNext.bind(this)} data={data}  />
          break

      case 'pause': 

          content = <TestPause onNext={this.onNext.bind(this)}  />
          break;

      case 'stop':

          content = <TestStop onNext={this.onNext.bind(this)} data={data}  />
          break;
    }

    return content
  }

  render() {

    const { step } = this.state
    let classNames = ['test']

    if (step !== 'unsure')
      classNames.push('panel')

    return (
      <section className={classNames.join(' ')} aria-label='setup' role="main">
      
          { this.getContent() }

      </section>
    );
  }
}

Test.propTypes = {
  onNext: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  setup: PropTypes.object,
  onRepeatPractice: PropTypes.func,
  unsure: PropTypes.number,
  question: PropTypes.number
}