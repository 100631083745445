import { Component } from 'react'
import _ from 'lodash'

// uses lodash debounce to stop multiple clicks
// see: http://sandny.com/2017/11/01/debounce-and-avoid-multiple-click-event-generation-on-react-js-components-lodash/

export default  class ClickableElement extends Component {
  static INTERVAL = 3000;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    const { onClick, disabled } = nextProps;
    this.setState({
      onClick,
      disabled,
    });
  }

  onClickDebounced(e) {
    const { onClick, disabled } = this.state;
    if (!disabled && _.isFunction(onClick)) {
      onClick(e);
    }
  }

  componentWillMount() {
    this.componentWillReceiveProps(this.props);
    const { clickWait } = this.props;
    const wait = clickWait || ClickableElement.INTERVAL;
    this.onElementClicked = _.debounce(this.onClickDebounced,
     wait,
     {
      maxWait:wait,
      leading: true,
      trailing: false,
    });
  }
}