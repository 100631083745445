import React, { Component } from 'react'
import { Text } from 'react-easy-i18n'
import SoundButton from '../../soundButton/soundButton'
import config from '../../../common/config'
import { pad, capitalizeFirstLetter } from '../../../common/string'
import PropTypes from 'prop-types'

import './chooseSound.scss'

export default class ChooseSound extends Component {

  constructor(props) {

      super(props)

      // default values

      this.SAMPLE_DURATION = 1.2       // length of a sample in seconds
      this.INTRO_DURATION = 0.5         // time before first sound seconds
      this.BUTTON_NO = 3

      const { files } = props

      // get sound duration from setup

      if (files) {

        const options = files.audioOptions

        this.SAMPLE_DURATION = options[0].duration
        this.INTRO_DURATION = options[0].start
      }

      // only include variable in state cause a redraw

      this.state = { selectedButtonId: -1 }    // no sound button active initially, links disabled
          
      this.audioPositionTimerId = null
      this.bufferTimerId = 0
      this.canplaythrough = false
      this.played = false
      this.timestamp = 0
  }

  initState() {

    this.setState({ selectedButtonId: -1})

    this.killTimers()

    this.played = false
    this.timestamp = 0
    this.canplaythrough = false
  }

  getActiveSoundId() {

    let audio = this.refs.audio

    let activeSoundId = -1

    let t = this.props.currentTime

    // we need this function to render the buttons
    // if the audio is not yet loaded get currentTime from props

    if (audio && this.isAudioLoaded())
      t = audio.currentTime

    if (t >= (this.INTRO_DURATION + 3 * this.SAMPLE_DURATION) )
      activeSoundId = 3
    else
    if (t >= (this.INTRO_DURATION + 2 * this.SAMPLE_DURATION) && t <= (this.INTRO_DURATION + 3 * this.SAMPLE_DURATION) )
      activeSoundId = 2
    else
    if (t >= (this.INTRO_DURATION + this.SAMPLE_DURATION) && t <= (this.INTRO_DURATION + 2 * this.SAMPLE_DURATION) )
      activeSoundId = 1
    else
    if (t >= this.INTRO_DURATION && t <= (this.INTRO_DURATION + this.SAMPLE_DURATION) )
      activeSoundId = 0
    
    return activeSoundId
  }

  startAudioTimer() {

    let audio = this.refs.audio

    let self = this

    if (audio) {

      audio.onended = function() {

        // kill the timer

        clearInterval(self.audioPositionTimerId)
        self.audioPositionTimerId = null

        // signal a redraw

        self.setState({ activeSoundId: self.BUTTON_NO })

        // get the timestamp

        self.timestamp = new Date().getTime()
  
        if (config.autoAnswer)
            self.onNext(1)
      }

      if (!this.audioPositionTimerId) {

        this.audioPositionTimerId = setInterval(() => {
  
          const x = this.getActiveSoundId()
  
          if (x !== this.state.activeSoundId)
            this.setState({activeSoundId: x})
    
        }, 200)
      }

    }
  }

  startBufferTimer() {

    // wait before starting playback

    if (this.bufferTimerId > 0)   // timer already running
      return

    const { replay, canPlay } = this.props

    let t = replay ? config.replayBufferTime : config.bufferTime

    if (config.autoAnswer || !canPlay)
      t = 0

    this.bufferTimerId = setTimeout(() => {

      // buffer time has finished, if audio is loaded play it

      this.bufferTimerId = -1   // -1 means the timer played and finished

      if (this.isAudioLoaded()) {
        this.playSound()
      }
    }, t)

  }

  killTimers() {

    // kill all timers

    if (this.bufferTimerId > 0)
      clearTimeout(this.bufferTimerId) 

    if (this.audioPositionTimerId)
      clearTimeout(this.audioPositionTimerId) 

    this.bufferTimerId = 0
    this.audioPositionTimerId = null
  }

  //-------------------------------------------------

  onAudioLoaded() {

    this.initAudio()    

    // we need to handle the case where the audio finishes loading after the buffer timer has finished
    // this could occur on a slow internet connection      

    if (this.bufferTimerId === -1 && !this.played)
      this.playSound()
  }

  isAudioLoaded() {

    return (this.canplaythrough)
  }

  onCanPlayThrough() {

    // this is actually triggered multiple times but we are only interested in the first time

    if (!this.canplaythrough) {

      this.canplaythrough = true

      if (this.isAudioLoaded()) {
        this.onAudioLoaded()
      }
    }
  }

  componentDidMount() {

      // for IOS devices we need to call audio.load

      let audio = this.refs.audio

      if (audio && this.isIOS())
        audio.load()

    this.initState()
    this.startBufferTimer()
  }

  componentWillUnmount() {

    this.killTimers()
  }

  componentDidUpdate(prevProps) {

    if (this.props.question !== prevProps.question || this.props.repeats !== prevProps.repeats) {

      // different question or repeat of the same question

      let audio = this.refs.audio

      if (audio && this.isIOS())
        audio.load()

      this.initState()
      this.startBufferTimer()

      if (this.props.repeats !== prevProps.repeats)
      {
        let audio = this.refs.audio

        // if we dont reload the audio on repeat onAudioLoaded is not triggered and the audio does not play

        if (audio)
          audio.load()
      }
    }
  }

  onNext(id) {

    if (this.props.onNext) {

      // cant click until all timers have finished

      //if (!this.audioPositionTimerId && !this.bufferTimerId) {

        let t = new Date().getTime()
  
        t -= this.timestamp    // subtract time since test question started
        t /= 1000         // convert to seconds

        this.setState({selectedButtonId: id})
  
        this.props.onNext(id, t)
      }
    //}
  }

  onRepeat() {

    if (this.props.onRepeat)
      this.props.onRepeat()
  }

  //-----------------------------------------------------------------------------------------
  // audio specific functions

  initAudio() {

    // cant reference audio object parameters until audio is loaded

    const { currentTime, data } = this.props

    let audio = this.refs.audio

    if (audio) {

      audio.currentTime = (currentTime) ? currentTime : 0   // rewind
      audio.volume = config.percentToVolume(data.audioLevelPercentage)
    }
  }

  isIOS() {

    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios

      var iDevices = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ];
    
      if (!!navigator.platform) {
        while (iDevices.length) {
          if (navigator.platform === iDevices.pop()){ return true; }
        }
      }
    
      return false;
  }

  playSound() {

    const { canPlay } = this.props

    if (!canPlay)
      return

    // assumes audio is loaded

    let audio = this.refs.audio

    if (audio && this.isAudioLoaded()) {

      if (audio.currentTime < this.INTRO_DURATION + (this.BUTTON_NO * this.SAMPLE_DURATION))
      {
        audio.play()

        this.startAudioTimer()
      }
      else {
        this.timestamp = new Date().getTime()
      }

      this.played = true
    }
  }

  onAudioError(msg) {

    this.initState()

    if (this.props.onError)
      this.props.onError('error loading audio file')
  }

  //-----------------------------------------------------------------------------------------
  // code diverges between practice and test below

  onStop() {

    if (this.props.onStop) 
      this.props.onStop()
  }

  onPause() {

    if (this.props.onPause) {

      let audio = this.refs.audio

      // we can pause at any time
      // before, during or after sound plays

      if (audio && this.isAudioLoaded())
        this.props.onPause(audio.currentTime)
      else
        this.props.onPause(0)
    }
  }

  render() {

    const { selectedButtonId } = this.state

    const { question, data, setup, files, repeats, canPlay } = this.props

    let activeSoundId = this.getActiveSoundId()

    // if sound is playing or not yet played links are disabled

    let disableLinks = (activeSoundId < this.BUTTON_NO)

    // if we have selected a sound disable links and bettons so user knows something has happend and not to press again

    if (selectedButtonId >= 0) {
      disableLinks = true
      activeSoundId = -1
    }

    // if we cant play its because we stopped the test
    // in this case nothing is disabled and you can press a button, no sound plays

    if (!canPlay) {
      disableLinks = false
      activeSoundId = this.BUTTON_NO
    }
    
    const firstName = (data && data.firstName ) ? capitalizeFirstLetter(data.firstName) : 'First Name'

    var buttons = Array(this.BUTTON_NO).fill(0)

    let file
    
    if (setup && files && files.audioFiles && files.audioFiles.length > 0)
      file = setup.audioFileUrl + '/' + ((question < files.audioFiles.length) ? files.audioFiles[question].file : files.audioFiles[0].file)

    const t = 'test.chooseSound'

    return (
        <div className='step choose-sound'>

              <h1 className="mar-b-30"><Text text={`${t}.sound`} /> { pad(question+1,2) } </h1>

              <h3 className="bold">{firstName}<Text className='t1' text={`${t}.t1`}/></h3>

              <p className="mar-t-10"><Text className='t2' text={`${t}.t2`}/></p>

              <section className='buttons' aria-label='region'>

                  {
                    buttons.map((id, i) => {

                      let buttonState = SoundButton.UNPLAYED

                      if (i === activeSoundId)
                        buttonState = SoundButton.ACTIVE
                      else
                      if (activeSoundId > -1 && i < activeSoundId)
                        buttonState = SoundButton.PLAYED
                    
                      return <SoundButton 
                      key={i} 
                      id={i+1}
                      selected={selectedButtonId === i+1} 
                      buttonState={buttonState} 
                      canClick={activeSoundId === this.BUTTON_NO}
                      onClick={this.onNext.bind(this)} />
                    })
                  }

              </section>

              <audio ref='audio' 
                    src={file}
                    preload="auto"
                    autoPlay={false}
                    onError={this.onAudioError.bind(this)} 
                    // onLoadStart={this}
                    // onDurationChange={this}
                   //  onLoadedMetadata={this}
                    // onLoadedData={}
                    // onProgress={}
                    // onCanPlay={}
                    onCanPlayThrough={this.onCanPlayThrough.bind(this)}>
                    </audio>

              <section className='controls'>

                <button className={`btn btn-link btn-repeat ${disableLinks || (config.maxRepeats - repeats === 0) ? "disabled": ""}`} onClick={this.onRepeat.bind(this)}>
                
                  {
                    (disableLinks || (config.maxRepeats - repeats === 0) ) ?
                      <img src={config.getImage('Icon_repeat_disabled.svg')} alt='repeat' />
                      :
                      <img src={config.getImage('Icon_repeat.svg')} alt='repeat' />
                  }

                  <Text text={`${t}.btn1`} />
                  <span className='repeats-container'>
                  (
                  {config.maxRepeats - repeats}
                  <Text className='t3' text={`${t}.t3`} />
                  {config.maxRepeats}
                  <Text className='t4' text={`${t}.t4`} />
                  )
                </span>
                </button>

                

                <button className='btn btn-link btn-pause' onClick={this.onPause.bind(this)}>
                  <img src={config.getImage('Icon_pause.svg')} alt='pause' />
                  <Text text='test.pauseButton' />
                </button>

              </section>

              <button className={`btn btn-link btn-stop ${disableLinks? "disabled": ""}`}  onClick={this.onStop.bind(this)}><Text text={`${t}.btn2`} /></button>

        </div>
    )
  }
}

ChooseSound.propTypes = {
  files: PropTypes.object,
  data: PropTypes.object,
  setup: PropTypes.object,
  currentTime: PropTypes.number,
  replay: PropTypes.bool,
  question: PropTypes.number.isRequired,
  repeats: PropTypes.number,
  canPlay: PropTypes.bool,

  onNext: PropTypes.func,
  onError: PropTypes.func,
  onStop: PropTypes.func,
  onPause: PropTypes.func,
  onRepeat: PropTypes.func
}