import React, { Component } from 'react'
import { Text } from 'react-easy-i18n'
import SoundBars from './soundbars/soundbars'
import ClickableElement from '../clickableElement/clickableElement'
import _ from 'lodash'
import PropTypes from 'prop-types'

import './soundButton.scss';

//import { timingSafeEqual } from 'crypto';

export class TouchButton extends ClickableElement {

  render() {
    
    const otherProps = _.omit(this.props, ['onClick', 'type']);
    const t = 'soundButton'

    return (
      <button
      onClick={e => this.onElementClicked(e)}
      type="button"
      {...otherProps}>

      <Text text={`${t}.sound`} /> 
      <span>&nbsp; {otherProps.id}</span>
      </button>
      );
    }
  }

  export default class SoundButton extends Component {

    static UNPLAYED = 0
    static ACTIVE = 1
    static PLAYED = 2

    onClick(e) {

      const { id, canClick } = this.props

      if (this.props.onClick && canClick)
        this.props.onClick(id)
    }

    componentDidMount() {

      // autoFocus not working

      // const btn = this.refs.btn1

      // if (btn) {
        //     btn.focus()
        // }
      }

      componentDidUpdate() {

        // autoFocus not working

        // const btn = this.refs.btn1

        // if (btn) {
          //     btn.focus()
          // }
        }

        render() {

          const { id, buttonState, selected } = this.props

          const t = 'soundButton'

          switch (buttonState) {
            case SoundButton.UNPLAYED: 
            default:

            return <button ref={'btn' + id} className='col-sm btn btn-sound disabled' disabled aria-disabled='true' >
            <Text text={`${t}.sound`} /> &nbsp; {id}
            </button>

            case SoundButton.ACTIVE: 

            return <button ref={'btn' + id} className='col-sm btn btn-sound active' disabled aria-disabled='true' >
            <SoundBars animate={false} />
          {/* <img className='soundbars-image' src={config.getImage('noun_Sound Wave_80779.svg')} alt='soundbars' /> */}
          </button>

          case SoundButton.PLAYED: 

          return <TouchButton ref={'btn' + id} id={id} className={`col-sm btn btn-sound ${selected ? "selected": ""}`} onClick={this.onClick.bind(this)} />
          
        }
      }
    }

    SoundButton.propTypes = {
      onClick: PropTypes.func,
      id: PropTypes.number,
      buttonState: PropTypes.number,
      selected: PropTypes.bool
    }