import React, { Component } from "react";
//import logo from './logo.svg';

import Header from "../header/header";
import Footer from "../footer/footer";
import Begin from "../begin/begin";
import Home from "../home/home";
import Setup from "../setup/setup";
import Practice from "../practice/practice";
import Test from "../test/ciTest";
import Result from "../result/result";
import localise from "../../common/localise";
import config from "../../common/config";
import Progress from "../progress/progress";
import cochlearAPI from "../../cochlearAPI/cochlearAPI";
import marketoAPI from "../../marketoAPI/marketoAPI";
import Loading from "../loading/loading";
import PropTypes from "prop-types";
import "babel-polyfill";

import "./App.scss";

(function(w) {
  w.URLSearchParams =
    w.URLSearchParams ||
    function(searchString) {
      var self = this;
      self.searchString = searchString;
      self.get = function(name) {
        var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
          self.searchString
        );
        if (results == null) {
          return null;
        } else {
          return decodeURI(results[1]) || 0;
        }
      };
    };
})(window);

export default class App extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef(); // Create a ref object

    // utm params submited into marketo
    // search gets the required params out of the url location
    let search = this.props.location
      ? new URLSearchParams(this.props.location.search)
      : null;
    let utm_source = search.get("utm_source") || "";
    let utm_campaign = search.get("utm_campaign") || "";
    let utm_content = search.get("utm_content") || "";
    let utm_medium = search.get("utm_medium") || "";
    let utm_term = search.get("utm_term") || "";
    let utm = {
      source: utm_source,
      campaign: utm_campaign,
      content: utm_content,
      medium: utm_medium,
      term: utm_term
    };

        let country = config.defaultCountryCode;

    if (props && props.match) {
      // configured to only work for uk or us, then default to au (in conjunction with Route paths in index.js)
      if (props.match.path === "/uk") {
          country = "uk";
      } else if (props.match.path === "/us") {
          country = "us";
      }
      // Use this when we are using a fuller set of country language files
      // It won't work as expected because of the changes made to lock down Routes to AU only
      // -------------------------------------------------------------------------------------------
      // if (props.match.params.crap && props.match.params.country) {
      //   country = props.match.params.country;
      // } else if (props.match.params.crap && !props.match.params.country) {
      //   country = props.match.params.crap;
      // }
    }

    // user information
    let user = {
      // id fields

      firstName: "",
      lastName: "",
      email: "",
      country: "",
      Phone: "",
      Phonetype: "0", // default it to no selection   
      howManyEars: "",
      yearsOfHearingLoss: "",
      audioLevelPercentage: config.defaultAudioLevelPercentage,
      postcode: "",
      hearingAidsHowOften: "",
      ageRange: "",
      marketingConsent: false,
    };



    // in devMode make some default values

    if (config.devMode) {
      user.firstName = "john";
      user.lastName = "smith";
      user.email = "john.smith@mercerbell.com.au";
      user.postcode = "2000";
      user.howManyEars = "Both ears";
      user.yearsOfHearingLoss = "11-15";
      user.ageRange = "50-64";
      user.hearingAidsHowOften = "Always";
    }

    // set language for text

    localise.init(country);
    // add bestDescription on countries where it's enabled
    if (localise.getString('setup.form.enableBestDescription') === 'true') {
      user.bestDescription = ""
    }

    // set the document language for screen readers

    document.documentElement.setAttribute("lang", localise.code);

    // right to left rendering

    if (localise.rtl) document.documentElement.setAttribute("dir", "rtl");

    this.state = {
      phase: "home",
      question: 0,
      loading: false,
      APIError: "",
      unsure: 0, // the number of times the user pressed unsure in practice

      // data
      country,
      user,
      utm,
      setup: null,
      score: null
    };

    // if we are budy doing an api call and we wish to do something else without
    // waiting for the call to finished and return a response set ignoreAPIResponse to true

    this.busy = false; // if we are doing an cochlearAPI call set this to true
    this.ignoreAPIResponse = false;
  }

  initState() {
    return {
      question: 0,
      loading: false,
      APIError: "",
      unsure: 0
    };
  }

  componentDidMount() {
    // popup a warning if we try to close the tab
    // catch close browser tab
    // window.addEventListener("beforeunload", function (e) {
    //   var confirmationMessage = "\o/";
    //   (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    //   return confirmationMessage;                            //Webkit, Safari, Chrome
    // });
  }

  onNext(data) {
    console.log("app.onNext");

    // goto next phase

    const functionName = "app.onNext: ";

    // user pressed the next button

    let { phase, setup, user, unsure, utm  } = this.state; //country

    switch (phase) {
      case "home":
        let nextState = this.initState();
        nextState.phase = "begin";
        this.setState(nextState);
        break;

      case "begin":
        // ready to start registration, call setup

        this.setState({ loading: true, user, APIError: "", score: null });
        // code to add the user country name by default

        let c = localise.getString('setup.form.countryList')
        user.country = localise.getfirst(c);

        
        this.busy = true; // busy doing api call

        cochlearAPI
          .setup()
          .then(res => {
            console.log(functionName, " setup success: ", res);

            let nextState = this.initState();
            nextState.phase = "setup";
            nextState.setup = res;

            this.setState(nextState);
            this.busy = false;
          })
          .catch(err => {
            this.setState({ loading: false, APIError: err });
            this.busy = false;
          });

        break;

      case "setup":
        // registration complete, submit to marketo

        if (setup && setup.id) {
          // call marketo non blocking
      
          //if (country === "us")
          //{
            // don't send form data to marketo if US
          //}
          //else
          //{         
            marketoAPI.sendNonBlocking(
              marketoAPI.REGISTER,
              data,
              setup.id,
              null,
              utm
            );
          //}
          let nextState = this.initState();
          nextState.phase = "practice";
          nextState.user = data;
          this.setState(nextState);
        } else {
          this.setState({
            loading: false,
            APIError:
              functionName + " Run cochlearAPI.setup before calling marketoAPI"
          });
        }

        break;

      case "practice":
        nextState = this.initState();
        nextState.phase = "test";
        this.setState(nextState);
        break;

      case "test":
        nextState = this.initState();
        nextState.unsure = unsure; // actually we dont want to clear this, we need it in test
        nextState.phase = "result";
        this.setState(nextState);
        break;

      case "result":
        const json = { CI_Screener_CTA__c: data };

        marketoAPI.sendNonBlocking(marketoAPI.CTA, user, setup.id, json, utm);
        break;

      default:
        // some unknown phase

        nextState = this.initState();
        nextState.phase = "home";
        this.setState(nextState);
        break;
    }
  }

  onSkipPractice() {
    console.log("app.onSkipPractice: busy = ", this.busy);

    if (this.busy) this.ignoreAPIResponse = true;

    this.setState({ phase: "test", question: 0, loading: false, APIError: "" });
  }

  //onProgress(progressStep) {

  //this.setState({progressStep})
  //}

  onHeaderClick(next) {
    // a shortcut for debuging

    this.setState({ loading: true, APIError: "" });

    const { user, utm } = this.state;

    this.busy = true; // busy doing api call

    cochlearAPI
      .setup()
      .then(res => {
        // do a non blocking call to marketo

        marketoAPI.sendNonBlocking(
          marketoAPI.REGISTER,
          user,
          res.id,
          null,
          utm
        );

        let nextState = this.initState();
        nextState.setup = res;
        nextState.score = null;

        // a typical score result

        let score = {
          action: "END_ACTUAL_TEST",
          actualTestProgress: { percentage: 100 },
          detailedResults: {
            candidacy: 3,
            scorePercentage: 25,
            testDetails:
              "<ul style='margin:0'><li>J - L</li><li>J - M</li><li>J - P</li><li>J - S</li><li>J - T</li><li>K - P</li><li>K - T</li><li>L - M</li><li>L - N</li><li>L - P</li><li>L - S</li><li>L - T</li><li>M - P</li><li>N - K</li><li>N - P</li><li>S - K</li><li>S - M</li><li>S - N</li><li>S - P</li><li>S - T</li><li>T - P</li></ul>"
          },
          id: "CICS-f9ec9b1e-3652-11e9-a7b2-2613db01d220",
          message: "",
          practiceProgress: { percentage: 0 },
          status: "OK",
          version: "1.0"
        };

        switch (next) {
          case "test":
          default:
            nextState.phase = "test";
            break;

          case "practice":
            nextState.phase = "practice";
            break;

          case "strong candidate":
            nextState.phase = "result";
            score.detailedResults.candidacy = config.DEFINITE_CANDIDATE;
            nextState.score = score;
            break;

          case "likely candidate":
            nextState.phase = "result";
            score.detailedResults.candidacy = config.LIKELY_CANDIDATE;
            nextState.score = score;
            break;

          case "possible candidate":
            nextState.phase = "result";
            score.detailedResults.candidacy = config.POSSIBLE_CANDIDATE;
            nextState.score = score;
            break;

          case "not a candidate":
            nextState.phase = "result";
            score.detailedResults.candidacy = config.NOT_A_CANDIDATE;
            nextState.score = score;
            break;

          case "unsure":
            nextState.phase = "test";
            nextState.unsure = config.SHOW_UNSURE_SCREEN_CLICK_NO;
            break;
        }

        this.setState(nextState);
        this.busy = false;
      })
      .catch(err => {
        this.setState({
          APIError: err,
          loading: false,
          score: null,
          unsure: 0
        });
        this.busy = false;
      });
  }

  onRepeatPractice() {
    let { setup } = this.state;

    // reset the practice results

    if (setup && setup.practiceFiles && setup.practiceFiles.audioFiles) {
      setup.practiceFiles.audioFiles.forEach(f => {
        delete f.selection;
        delete f.secondsToRespond;
        delete f.replays;
      });
    }

    let nextState = this.initState();
    nextState.phase = "practice";
    nextState.setup = setup;
    nextState.score = null;

    this.setState(nextState);
  }

  onRepeatTest() {
    // we need new audio files to test against

    this.setState({ loading: true, APIError: "" });

    this.busy = true; // busy doing api call

    cochlearAPI
      .setup()
      .then(res => {
        let nextState = this.initState();

        // clear unsure this time

        nextState.phase = "test";
        nextState.setup = res; // reset setup
        nextState.score = null; // reset score

        this.setState(nextState);
        this.busy = false;
      })
      .catch(err => {
        this.setState({
          loading: false,
          APIError: err,
          score: null,
          unsure: 0
        });
        this.busy = false;
      });
  }

  getAudioFiles() {
    // depends on what phase we are in

    const { phase, setup } = this.state;

    if (
      setup &&
      setup.actualTestFiles &&
      setup.actualTestFiles.audioFiles &&
      setup.practiceFiles &&
      setup.practiceFiles.audioFiles
    ) {
      return phase === "test"
        ? setup.actualTestFiles.audioFiles
        : setup.practiceFiles.audioFiles;
    }

    return [];
  }

  onNextQuestion() {
    // increment the question but dont submit

    const { question } = this.state;

    const files = this.getAudioFiles();

    const nextQuestion = question <= files.length - 1 ? question + 1 : question;

    this.setState({ question: nextQuestion, APIError: "" });
  }

  onSubmit(question, selection, secondsToRespond, replays) {
    // a sound button was pressed

    // question: starts at 0
    // selection: 1..3
    // secondsToRespond:
    // replays: how many times the sound was repeated

    const functionName = "app.onSubmit: ";

    // dont display loading icon, too distracting

    let { setup, user, phase, unsure, utm } = this.state;

    if (!setup) setup = {};

    //console.log(functionName + ' question = ' + question + ' selection = ' + selection + ' secondsToRespond = ' + secondsToRespond + ' replays = ', replays)

    const files = this.getAudioFiles();

    let f = null;

    if (question >= 0 && question < files.length) {
      f = files[question];

      f.selection = selection; // 1..3, 0 is nothing selected

      if (config.autoAnswer) selection = f.correct;

      f.selection = selection; // 1..3, 0 is nothing selected
      f.secondsToRespond = secondsToRespond;
      f.replays = replays ? replays : 0;
    }

    // construct the payload for score endpoint

    const json = {
      version: setup.version,
      id: setup.id,
      form: {
        yearsOfHearingLoss: user.yearsOfHearingLoss,
        audioLevelPercentage: user.audioLevelPercentage // iI dont think is used by the cochlear API anymore
      },
      practiceFiles: setup.practiceFiles,
      actualTestFiles: setup.actualTestFiles
    };

    //console.log(functionName + 'payload: ', json)

    // save the current state
    // cochlearAPI.score is an asynchronous call so we set setup state before here rather than install the then

    this.setState({ setup, unsure });

    this.busy = true; // busy doing api call

    return cochlearAPI
      .score(json)
      .then(res => {
        if (phase === "practice")
          console.log(
            functionName +
              "phase = " +
              phase +
              " question = " +
              question +
              " selected = " +
              selection +
              " correct = " +
              f.correct +
              " action = " +
              res.action +
              " practice complete = " +
              res.practiceProgress.percentage +
              "%"
          );
        else
          console.log(
            functionName +
              "phase = " +
              phase +
              " question = " +
              question +
              " selected = " +
              selection +
              " correct = " +
              f.correct +
              " action = " +
              res.action +
              " test complete = " +
              res.actualTestProgress.percentage +
              "%"
          );

        let nextState = { score: res, unsure };

        // an unanswered question sets unsure to true

        if (selection === 0) {
          nextState.unsure++;
        }

        switch (res.action) {
          case "PRACTICE_CONTINUE":
          case "ACTUAL_TEST_CONTINUE":
            // next question please

            if (this.ignoreAPIResponse) this.ignoreAPIResponse = false;
            else this.onNextQuestion();

            break;

          case "START_ACTUAL_TEST":
            // practice test finished, start test

            if (this.ignoreAPIResponse) {
              this.ignoreAPIResponse = false;
            } else {
              nextState.phase = "test";
              nextState.loading = false;
              nextState.APIError = "";
              nextState.question = 0;
            }

            break;

          case "END_ACTUAL_TEST":
            //console.log(functionName, 'result: ', res)

            // test finished, submit results to marketo, goto results

            marketoAPI.sendNonBlocking(
              marketoAPI.TEST_RESULT,
              user,
              setup.id,
              res.detailedResults,
              utm
            );

            if (this.ignoreAPIResponse) {
              this.ignoreAPIResponse = false;
            } else {
              nextState.phase = "result";
              nextState.loading = false;
              nextState.APIError = "";
              nextState.question = 0;
            }
            break;

          default:
            console.log(functionName, "api score error");

            this.setState({ loading: false, APIError: "api score error" });
            break;
        }

        this.setState(nextState);
        this.busy = false; // not busy doing api call anymore
      })
      .catch(err => {
        console.log(functionName, err);

        this.setState({ loading: false, APIError: err });
        this.busy = false; // not busy doing api call anymore
      });
  }

  onAudioError(msg) {
    // error loading audio file

    this.setState({ APIError: msg });
  }

  getContent() {
    // get page content depending on phase

    const { phase, user, setup, question, score, unsure } = this.state;

    let content;

    switch (phase) {
      case "home":
      default:
        content = <Home onNext={this.onNext.bind(this)} />;
        break;

      case "begin":
        content = <Begin onNext={this.onNext.bind(this)} />;
        break;

      case "setup":
        content = (
          <Setup data={user} setup={setup} onNext={this.onNext.bind(this)} />
        );
        break;

      case "practice":
        content = (
          <Practice
            question={question}
            data={user}
            setup={setup}
            onSubmit={this.onSubmit.bind(this)}
            onError={this.onAudioError.bind(this)}
            onNext={this.onNext.bind(this)}
            onSkipPractice={this.onSkipPractice.bind(this)}
          />
        );
        break;

      case "test":
        content = (
          <Test
            question={question}
            data={user}
            setup={setup}
            onSubmit={this.onSubmit.bind(this)}
            onError={this.onAudioError.bind(this)}
            onRepeatPractice={this.onRepeatPractice.bind(this)}
            unsure={unsure}
          />
        );
        break;

      case "result":
        content = (
          <Result
            data={user}
            score={score}
            onNext={this.onNext.bind(this)}
            onRepeatTest={this.onRepeatTest.bind(this)}
          />
        );
        break;
    }

    return content;
  }

  render() {
    const { phase, APIError, loading } = this.state;

    const content = this.getContent();

    let classNames = ["page", "container-fluid"];

    // right to left rendering

    if (localise.rtl) classNames.push("rtl");

    const phases = ["home", "begin", "setup", "practice", "test", "result"];

    let phaseid = 0;

    for (let i = 0; i < phases.length; i++) {
      if (phases[i] === phase) {
        phaseid = i - 1;
        break;
      }
    }

    return (
      <main className={classNames.join(" ")}>
        {loading ? <Loading /> : null}

        <div className="api-error">{APIError}</div>

        <Header onHeaderClick={this.onHeaderClick.bind(this)} />

        <div className="app-body row">
          {phase === "home" ? (
            content
          ) : (
            <div className="container body-content">
              <Progress phase={phaseid} />

              {content}
            </div>
          )}
        </div>

        <Footer />
      </main>
    );
  }
}

App.propTypes = {
  match: PropTypes.object
};
