import React, { Component } from 'react';

import PracticeIntro1 from './intro1/intro1'
import PracticeIntro2 from './intro2/intro2'
import PracticeThanks from './thanks/thanks'
import PracticeHelp from './help/help'
import { Text } from 'react-easy-i18n';
import ChooseSound from './chooseSound/chooseSound'
import localise from '../../common/localise'
import PropTypes from 'prop-types'

import './practice.scss';

export default class Practice extends Component {

  constructor(props) {

    super(props)

    this.state = {step: 'intro1', repeats: 0, currentQuestionRepeatCount: 0}
  }

  componentDidMount() {

    this.setState({currentQuestionRepeatCount: 0})
  }

  componentDidUpdate(prevProps) {

      if (this.props.question !== prevProps.question) {

        this.setState({currentQuestionRepeatCount: 0})
      }
  }

  onNext(selection, t) {

    let { step, currentQuestionRepeatCount } = this.state

    const { question } = this.props

    switch ( step ) {
      default: step = 'intro1'; break;
      case 'intro1': step = 'intro2'; break;
      case 'intro2': step = 'practice';  break;
      case 'practice':

        if (this.props.onSubmit)
          this.props.onSubmit(question, selection, t, currentQuestionRepeatCount)

        // for the first question only show the thanks screen

        if (question === 0) 
          step = 'thanks'

        // show the help screen in response to user pressing unsure

        if (selection === 0)
          step = 'help'
      
        break

    case 'thanks':

        step = 'practice'
        break

    case 'help':

        step = 'practice'
        break
    }

    this.setState({step})

    // set the progress bar position

    // if (this.props.onProgress)
    //   this.props.onProgress(stepn * (1.0 / this.TOTAL_STEPS))
  }

  onRepeat() {

    const { repeats, currentQuestionRepeatCount } = this.state

    this.setState({repeats: repeats+1, currentQuestionRepeatCount: currentQuestionRepeatCount+1})
  }

  onSkip() {
    
    if (this.props.onSkipPractice)
      this.props.onSkipPractice()
  }

  onError(msg) {

    if (this.props.onError)
      this.props.onError(msg)
  }

  getContent() {

    const { step, repeats } = this.state

    const { data, setup, question } = this.props

    let content

    switch (step) {
      case 'intro1':
      default: 

        content = <PracticeIntro1 onNext={this.onNext.bind(this)} />
        break

      case 'intro2': 
      
        content = <PracticeIntro2 onNext={this.onNext.bind(this)} />
        break

      case 'practice': 
      
          content = <ChooseSound files={setup.practiceFiles}
                          question={question} 
                          setup={setup} 
                          repeats={repeats}
                          data={data} 
                          onNext={this.onNext.bind(this)} 
                          onRepeat={this.onRepeat.bind(this)}
                          onError={this.onError.bind(this)}
                          />
          break;

      case 'thanks': 

          content = <PracticeThanks onNext={this.onNext.bind(this)} />
          break

      case 'help': 
      
          content = <PracticeHelp onNext={this.onNext.bind(this)} />
          break
    }

    return content
  }

  render() {

    const { step } = this.state

    const t = 'practice'

    const btnSkip= (step === 'intro2' || step === 'thanks' || step === 'practice') ?
      <button className='btn btn-link btn-skip'  onClick={this.onSkip.bind(this)} title={localise.getString(`${t}.skip`)}>
        <Text text={`${t}.skip`} />
      </button>
      :
      null

    let classNames = ['practice']

    if (step !== 'intro1')
      classNames.push('panel')

    return (

      <div className={classNames.join(' ')} role="main">

          { btnSkip }
      
          { this.getContent() }

          { (step !== 'intro1' && step !== 'thanks' && step !== 'help') ?

            <Text text='practice.footnote' className="footnote smallfont subcopy bold"/>
            :
            null
          }

      </div>
    );
  }
}

Practice.propTypes = {
  onNext: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  question: PropTypes.number.isRequired
}