// branding data
// see common/config.js for brandId

const branding = {
  cochlear: {
    documentTitle: "CI Screener",
    logo: "cochlear-logo.svg",
    mobilelogo: "Mobile-header-logo.svg",
    footerLogo: "cochlear-footer.svg",
    nal:
      "https://www.nal.gov.au/project/language-independent-test-of-candidacy-for-cochlear-implants/"
  }
};

export default branding;
