import React, { Component } from "react";
import { Text } from "react-easy-i18n";
import config from "../../common/config";
import branding from "../../common/branding";
import localise from "../../common/localise";

import "./footer.scss";

export default class Footer extends Component {
  CochlearFooter() {
    const brand = branding[config.brandId];
    const footerLogo = config.getImage(brand.footerLogo);
    // return html for a cochlear footer
    const t = "footer";
    const footerAddress = `${t}.address`;
    return (
      <section
        className="cochlear-footer container-fluid"
        aria-label="page footer"
      >
        <div className="content">
          <div className="footer-logo">
            <img src={footerLogo} alt="logo" />
            {/* Cochlear <span className='copyright'>&copy;</span> */}
          </div>
          <div className="footer-links">
            <a
              className="flex-nowrap"
              href={localise.getString("appSettings.privacyPolicy")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text text={`${t}.privacy`} />
            </a>

            <a
              className="flex-nowrap"
              href={localise.getString("appSettings.termsOfUse")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text text={`${t}.termsOfUse`} />
            </a>

            {(localise.getString("footer.enableCookieSettings") === "true") &&
              <a
                id="ot-sdk-btn"
                className="flex-nowrap optanon-toggle-display"
                href="##"
              >
                <Text text={`${t}.cookieSettings`} />
              </a>
            }
          </div>

          <div className="copyright">
          
            <Text text={`${t}.copyright`} />
            { // localisation returns a the id when the id of the string is not found in the language json
              // check key exists in language json before displaying. 
              localise.getString(footerAddress) !== footerAddress && 
                <React.Fragment>
                  <br/>              
                  <Text text={footerAddress} />
                </React.Fragment>
            }


          </div>
        </div>
      </section>
    );
  }

  render() {
    // footer content depends on the brand
    let content;

    switch (config.brandId) {
      default:
      case "cochlear":
        content = this.CochlearFooter();
        break;
    }
    return <footer className="app-footer row">{content}</footer>;
  }
}
