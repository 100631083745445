import React, { Component } from "react";
import { Text } from "react-easy-i18n";
import config from "../../../common/config";
import NextButton from "../../nextButton/nextButton";
import PropTypes from "prop-types";

import "./index.scss";
import localise from "../../../common/localise";

export default class SetupEars extends Component {
  constructor(props) {
    super(props);

    this.state = { selection: null };
  }

  onNext() {
    const { selection } = this.state;

    if (selection && this.props.onNext) this.props.onNext(selection);
  }

  onClick(id) {
    this.setState({ selection: id });
  }

  render() {
    const { selection } = this.state;

    const arr = [
      { id: "btn1", value: "One ear", image: "Icon_ear.svg" },
      { id: "btn2", value: "Both ears", image: "Icon_both_ears.svg" }
    ];

    const t = "setup.ears";

    const buttonText = selection === "One ear" ? t + ".t3" : t + ".nextButton";

    const helpText = selection === "One ear" ? t + ".error" : t + ".t4";

    let bottomContent;

    switch (selection) {
      case null:
      default:
        bottomContent = (
          <article className="help-text-container">
            <div>
              <img
                src={config.getImage("Mona-info.svg")}
                className="icon mona-lisa "
                alt="mona info"
              />
            </div>

            <Text className="info-text" text={helpText} />
          </article>
        );
        break;

      case "One ear":
        bottomContent = (
          <section>
            <article className="help-text-container">
              <div>
                <img
                  src={config.getImage("Mona-standard.svg")}
                  className="icon mona-lisa "
                  alt="mona info"
                />
              </div>

              <Text className="help-text" text={helpText} />
            </article>

            <NextButton
              label={buttonText}
              link={localise.getString("appSettings.oneEarAlternativeTest")}
              onClick={this.onNext.bind(this)}
            ></NextButton>
          </section>
        );

        break;

      case "Both ears":
        bottomContent = (
          <NextButton
            label={buttonText}
            onClick={this.onNext.bind(this)}
          ></NextButton>
        );

        break;
    }

    //autoFocus={i === 1}

    return (
      <div className="step ears">
        <p className="no-margin">
          <Text text={`${t}.t1`} />
        </p>

        <h1>
          <Text text={`${t}.t2`} />
        </h1>

        <div className="content">
          <section className="buttons" aria-label="buttons">
            {arr.map((x, i) => (
              <button
                key={i}
                id={i + 1}
                className={`btn radio-btn ${
                  selection === x.value ? "active" : ""
                }`}
                onClick={this.onClick.bind(this, x.value)}
                aria-label={localise.getString(t + "." + x.id)}
              >
                <span className="block">
                  <img src={config.getImage(x.image)} alt="ears" />
                </span>
                <Text text={`${t}.${x.id}`} />
              </button>
            ))}
          </section>

          <section className="bottom" aria-label="bottom section">
            {bottomContent}
          </section>
        </div>
      </div>
    );
  }
}

SetupEars.propTypes = {
  onNext: PropTypes.func
};
