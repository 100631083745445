import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import config from '../../../../common/config'
import PropTypes from 'prop-types'

import './volumeControl.scss';

export default class VolumeControl extends Component {

  constructor(props) {

    super(props)

    let n = props.value ? props.value : 50

    n = parseInt(n, 10)

    if (n < 0)
      n = 0

    if (n > 100)
      n = 100

      // n: 0-100 map to 0..1

    const x = n / 100

    // map x (0..1) to (0..(config.volumeSteps.length - 1))

    const volumeIndex = Math.round((config.volumeSteps.length - 1) * x)

    this.state = {volumeIndex, paused: true}
  }

  componentDidMount() {

    const { volumeIndex } = this.state

    let audio = this.refs.audio

    if (audio) {
      audio.volume = config.volumeSteps[volumeIndex]           // need to set the volume programatically 
      audio.load();
    }
  }

  onPlay() {

    let audio = this.refs.audio

    if (audio) {

      if (audio.paused) {
        audio.play();
        this.setState({paused: false})
      } else {
        audio.pause();
        this.setState({paused: true})
      }
    }
  }

  onChange(id) {

    let { volumeIndex } = this.state

    if (id > 0)
      volumeIndex++
    else if (id < 0)
      volumeIndex--

    if (volumeIndex < 0)
      volumeIndex = 0

    if (volumeIndex > config.volumeSteps.length-1)
      volumeIndex = config.volumeSteps.length-1

    this.setState({volumeIndex})

    // map 0..100 to 0..1

    let audio = this.refs.audio

    if (audio) {
      audio.volume = config.volumeSteps[volumeIndex];
    }

    // may be fractional

    const percent = volumeIndex / (config.volumeSteps.length-1) * 100

    if (this.props.onChange)
      this.props.onChange(Math.round(percent))
  }

  render() {

    const { file } = this.props

    let { paused, volumeIndex } = this.state

    let percent = Math.round(volumeIndex / (config.volumeSteps.length-1) * 100)

    // audio control is invisible

    // cant change volume in javascript for IOS

    const t = 'setup.volumeControl'

    const playButtonContent = paused ? 
    <span className='play-image'>
    <img src={config.getImage('Play.svg')} alt='play' /> 
    <Text text={`${t}.btn2`} />

    </span>
    :
    <div className='play-image'>
    <img src={config.getImage('Pause.svg')} alt='pause' />
    </div>

    // autoFocus={true}

    return (

    <div className='volume-control'>

    {
      file ? <audio id='theAudio' ref='audio' src={file} loop> </audio> : null
    }

    <div className=' buttons'>
    
    <button className='btn btn-volume minus' onClick={this.onChange.bind(this, -1)} aria-label='reduce volume'>
    <span className='plus-minus'>-</span>
    </button>

    <button className='btn btn-play' onClick={this.onPlay.bind(this)}  aria-label='play'>
    
    {playButtonContent}

    </button>

    <button className='btn btn-volume plus' onClick={this.onChange.bind(this, 1)} aria-label='increase volume'>
    <span className='plus-minus'>+</span>
    </button>

    </div>

    <div className='row percent'>

    <p><Text text={`${t}.t1`} /><span > {percent}%</span></p>
    
    </div>
    
    </div>

    )
  }
}

VolumeControl.propTypes = {
  value: PropTypes.number,
  file: PropTypes.string,
  onChange: PropTypes.func
}