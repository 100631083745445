import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import NextButton from '../../nextButton/nextButton'
import { capitalizeFirstLetter } from '../../../common/string'
import config from '../../../common/config'
import PropTypes from 'prop-types'

import './intro.scss';

export default class TestIntro extends Component {

  onNext() {

    if (this.props.onNext)
      this.props.onNext()
  }

  render() {

    const { altContent } = this.props
    const t = 'test.intro'
    const { data } = this.props
    const firstName = (data && data.firstName ) ? capitalizeFirstLetter(data.firstName) : null

    //autoFocus={true}
    return (
      <div className="step intro">
        <div>
          <img src={config.getImage('Mona-standard.svg')} className='icon mona-lisa ' alt='mona info' />
        </div>
        <h2 className="mar-b-30 mar-t-10">{firstName}<Text className='t1' text={`${t}.t1`}/></h2>
        <p><Text className='t2' text={`${t}.t2`} /></p>
        <section className='b' aria-label='region'>
          <p><Text  text={`${t}.b.t1`} /><br/>
          </p>
          <p><Text text={`${t}.b.t2`} /></p>
        </section>
        {
          altContent ?
          <NextButton className='alt-next-button' label={t + '.altNextButton'} onClick={this.onNext.bind(this)}></NextButton>
          :
          <NextButton label={t + '.nextButton'} onClick={this.onNext.bind(this)}></NextButton>
        }
      </div>
    )
  }
}

TestIntro.propTypes = {
  onNext: PropTypes.func,
  data: PropTypes.object,
  altContent: PropTypes.bool
}