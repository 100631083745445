import React, { Component } from 'react'
import { Text } from 'react-easy-i18n';
import localise from '../../common/localise'
import PropTypes from 'prop-types'

import './progress.scss';

export default class Progress extends Component {

  constructor(props) {

    super(props)

        // percentage offsets of the progress bar

        this.sliderPositions = [0, // begin
                                26, // setup
                                45, // practice
                                72, // test
                                100 // result
                                ]

        // set initial progress bar position

        this.state = {percent: 0}
      }

      setPercentageOffset() {

        // set current progress bar position
        // setTimeout make the animation happen in react

        let phase = this.props.phase
        let offset = this.props.step ? this.props.step : 0  // 0..1

        let percent = this.sliderPositions[phase]

        // offset: 0...1

        let a = this.sliderPositions[phase]
        let b = (phase < this.sliderPositions.length - 1) ? this.sliderPositions[phase+1] : 100

        // offset is percentage of phase width

        offset = 0

        percent += (b - a) * offset

        setTimeout(() => { this.setState({percent}) }, 0)
      }

      componentDidMount() {

        this.setPercentageOffset();
      }

      componentDidUpdate(prevProps) {

        if (this.props.phase !== prevProps.phase || this.props.step !== prevProps.step) {

          this.setPercentageOffset();
        }
      }

      render() {

        const { percent } = this.state

        let { phase } = this.props

        phase = parseInt(phase, 10)

        let style = {width: percent + '%'}

        let array = []

        this.sliderPositions.forEach((x,index) => array.push(index) )

        if (phase < 0 || phase >= this.sliderPositions.length)
          return null

        const progressText = localise.getString('progress.phase' + phase)

        return (

          <div className='the-progress-bar'>
          <div className="progress">
          <div role="progressbar" className="progress-bar" aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100" aria-valuetext={'step = ' + progressText} style={style}>
          <span className="sr-only">{percent}%</span>
          </div>
          </div> 


          <div className='phases' aria-hidden='true'>

          {
            array.map((x,i) => {

             let classes = ['dot']

             if (phase === x)
              classes.push("active")

            if (i < phase)
              classes.push('visited')


            return <div key={x} className={`phase ${phase === x ? 'active' : ''}`}>
            <div className="phasediv">
            <div className={classes.join(' ')}>
            <Text className='phase-step' text={`progressbar.phase${x+1}`} />
            <div className='inner'></div>
            </div>

            <Text className='text' text={`progress.phase${x+1}`} />
            </div>
            </div>
          }

          )
        }

        </div>

        </div>
        )
      }
    }

    Progress.propTypes = {
      phase: PropTypes.number.isRequired,
      step: PropTypes.string,
    }