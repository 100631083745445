import React, { Component } from 'react';

import ResultIntro from './intro/intro';
import ResultCandidate from './candidate/candidate';
import ResultNotACandidate from './notACandidate/notACandidate';
import config from '../../common/config'
import PropTypes from 'prop-types'

import './result.scss';

export default class Result extends Component {

  constructor(props) {

    super(props)

    this.state = {step: 'intro'}
  }

  componentDidUpdate(prevProps) {

    if (this.props.score !== prevProps.score) {

      this.setState({step: 'intro'})
    }
}

  onNext(id) {

    let { step } = this.state

    const { score } = this.props

    if (step === 'intro') {

      step = 'notACandidate'

      if (score && score.detailedResults) {

        switch (parseInt(score.detailedResults.candidacy, 10)) {
        case config.DEFINITE_CANDIDATE: 
        case config.LIKELY_CANDIDATE: 
        case config.POSSIBLE_CANDIDATE: 
        default:

          step = 'candidate'
          break

        case config.NOT_A_CANDIDATE: 

          step = 'notACandidate'
          break
        }
      }

      this.setState({step})
    }
    else {

      if (this.props.onNext)
        this.props.onNext(id)
    }
  }

  onRepeatTest() {

    if (this.props.onRepeatTest)
      this.props.onRepeatTest()
  }

  render() {

    const { data, score } = this.props

    const { step } = this.state

    let content

    const props = {
      data,
      score,
      onNext: this.onNext.bind(this),
      onRepeatTest: this.onRepeatTest.bind(this)
    }

    switch (step) {
      case 'intro': 
      default:
                content = <ResultIntro {...props} />
                break;

      case 'candidate': 
                content = <ResultCandidate {...props} />
                break;

      case 'notACandidate': 
                content = <ResultNotACandidate {...props} />
                break;
    }

    let classNames = ['result']

    if (step === 'intro')
      classNames.push('panel')
      
    return (

      <section className={classNames.join(' ')} aria-label='content' role="main">
      
          { content }

      </section>
    );
  }
}

Result.propTypes = {
  score: PropTypes.object,
  data: PropTypes.object,
  onRepeatTest: PropTypes.func,
  onNext: PropTypes.func,
}