import React, { Component } from "react";
import { Text } from "react-easy-i18n";
import config from "../../../common/config";
import PropTypes from "prop-types";

import "./notACandidate.scss";
import localise from "../../../common/localise";

export default class ResultNotACandidate extends Component {
  onNext(id) {
    if (this.props.onNext) this.props.onNext(id);
  }

  componentDidMount() {
    // const btn = this.refs.btn
    // if (btn)
    //     btn.focus()
  }
  buildfactslink()
  {
    const {country} = this.props.data;
    const link = country !== "Canada" ? localise.getString("appSettings.moreInformation") : localise.getString("appSettings.moreInformationAlt")
    if(localise.getString("appSettings.region") === "CAM")
    return(
      <a
      ref="btn"
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      className="btn btn-next"
      onClick={this.onNext.bind(this, "More Information")}
      title={localise.getString("result.notACandidate.discoverMore.btn1")}
    >
      <Text text={"result.notACandidate.discoverMore.btn1"} />
      <span className="arrow"></span>
    </a>
)

else
return(
  <a
  ref="btn"
  href={localise.getString("appSettings.moreInformation")}
  rel="noopener noreferrer"
  target="_blank"
  className="btn btn-next"
  onClick={this.onNext.bind(this, "More Information")}
  title={localise.getString("result.notACandidate.discoverMore.btn1")}
>
  <Text text={"result.notACandidate.discoverMore.btn1"} />
  <span className="arrow"></span>
</a>
)

  }
  render() {
    const t = "result.notACandidate";
    return (
      <section className="step not-a-candidate" aria-label="region">
        <section className="top" aria-label="region">
          <section className="panel" aria-label="region">
            <div className="content">
              <div>
                <img
                  src={config.getImage("Mona-standard.svg")}
                  className="icon mona-lisa "
                  alt="mona info"
                />
              </div>

              <div className="quote">
                <h2>
                  <Text className="t1" text={`${t}.t1`} />
                  <br />
                  <Text className="t1" text={`${t}.t11`} />
                </h2>

                <p>
                  <Text className="t2" text={`${t}.t2`} />
                </p>
              </div>

              <p>
                <Text className="t3" text={`${t}.t3`} />
              </p>

              <p>
                <Text className="t4" text={`${t}.t4`} />
              </p>
            </div>
          </section>
        </section>

        <section className="bottom" aria-label="bottom section">
          <article className="panel panel-border">
            <article className="content">
              <h2>
                <Text className="t1 bold" text={`${t}.discoverMore.t1`} />
              </h2>

              <Text className="t2 smallfont" text={`${t}.discoverMore.t2`} />

              {/* autoFocus={true}  */}
              {this.buildfactslink()}

            </article>
          </article>
        </section>
        <section className="disclaimer" aria-label="disclaimer">
          <p>
            <Text className="t2 smallfont bold" text={`${t}.t5`} />
            <br />
            <Text className="t2 smallfont" text={`${t}.t6`} />
            <br />
            <Text className="t2 smallfont" text={`${t}.t7`} />
            <br />
            <Text className="t2 smallfont" text={`${t}.t8`} />
            <br /><br />
            <Text className="t2 smallfont" text={`${t}.t9`} />                                    

          </p>
        </section>

      </section>
    );
  }
}

ResultNotACandidate.propTypes = {
  onNext: PropTypes.func
};
