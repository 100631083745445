import React, { Component } from 'react'
import { Text } from 'react-easy-i18n'
import localise from '../../../common/localise'
import NextButton from '../../nextButton/nextButton'
import config from '../../../common/config'
import PropTypes from 'prop-types'

import './index.scss';

export default class SetupYears extends Component {

  constructor(props) {

    super(props)

    const selectText = localise.getString('setup.years.select')

    this.state = { selection: selectText }
  }

  onNext() {

    const { selection } = this.state

    if (selection && this.props.onNext)
      this.props.onNext(selection)
  }

  onSelectChange(e) {

        //const id = e.target.id
        const val = e.target.value

        this.setState({selection: val})
      }

      render() {

        const { selection } = this.state

        const t = 'setup.years'

        const selectText = localise.getString(t + '.select')

        const yearsText = localise.getString(t + '.years')

        let options = [selectText]

        config.yearsOfHearingLossChoices.forEach(x => { options.push(x) })

        return (

          <div className="step years">
          
          <p className="no-margin"><Text text={`${t}.t1`} /></p>
          
          <h1><Text text={`${t}.t2`} /></h1>

          <div className='form-group'>

        {/* autoFocus={true} */}

        <select className="form-control" onChange={this.onSelectChange.bind(this)}  >

        {
          options.map((x,i) => <option key={x} value={x} >
                                  { (i === 0) ? selectText : x + ' ' + yearsText }
                                </option> )
        }
        
        </select>

        </div>

        <section aria-label='help text'>


        {
          selection === selectText ?
          <article className='help-text-container'>
          <div>
          <img src={config.getImage('Mona-info.svg')} className='icon mona-lisa ' alt='mona info' />
          </div>
          <Text text={`${t}.t3`} className="info-text" />
          </article>
          :

          <NextButton label={t + '.nextButton'} onClick={this.onNext.bind(this)}></NextButton>
        }

        </section>

        </div>
        )
      }
    }

    SetupYears.propTypes = {
      onNext: PropTypes.func
    }