import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import NextButton from '../../nextButton/nextButton'
import config from '../../../common/config'
import PropTypes from 'prop-types'

import './intro2.scss';

export default class PracticeIntro2 extends Component {

  onNext() {

    if (this.props.onNext)
      this.props.onNext()
  }

  render() {

    const t = 'practice.intro2'

    return (

      <div className="step intro2">
      
      <p><Text className='t1' text={`${t}.t1`}/> 1 / 6</p>

      <div>
      <img src={config.getImage('Mona-standard.svg')} className='icon mona-lisa ' alt='mona info' />
      </div>
      
      <h2><Text className='t2' text={`${t}.t2`} /></h2>

    {/* autoFocus={true} */}

    <NextButton label={t + ".nextButton"} onClick={this.onNext.bind(this)}></NextButton>

    </div>
    )
  }
}

PracticeIntro2.propTypes = {
  onNext: PropTypes.func
}