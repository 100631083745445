// config file

const config = {
  brandId: "cochlear", // see common/branding.js for brandIds
  apiURL: process.env.REACT_APP_CI_API, // cochlear api url
  imagePath: "/images/", // folder where the images live
  defaultCountryCode: "au", // country code is normally taken from the url
  maxRepeats: 2,
  devMode: process.env.REACT_APP_DEV_MODE === "true" ? true : false, // set to true for development
  autoAnswer: false, // answer questions automatically with no wait, useful for development

  bufferTime: 2000, // how long to wait before playback starts
  replayBufferTime: 1000, // how long to wait before replay starts

  breatherQuestion: 28, // at what question of the test to show 'You are making great progress'
  almostThereQuestion1: 45, // well done John, you're almost there...
  almostThereQuestion2: 65, // well done John, you're almost there... same message again

  DEFINITE_CANDIDATE: 3,
  LIKELY_CANDIDATE: 2,
  POSSIBLE_CANDIDATE: 1,
  NOT_A_CANDIDATE: 0,

  SHOW_UNSURE_SCREEN_CLICK_NO: 3,

  yearsOfHearingLossChoices: [
    "0-5",
    "6-10",
    "11-15",
    "16-20",
    "21-25",
    "26-30",
    "31-35",
    "36-40",
    "41-45",
    "46-50",
    "51-55",
    "55-60",
    "60+"
  ],
  yearsOfHearingLossChoicesCAM: [
    "0-5",
    "6-10",
    "11-15",
    "16-20",
    "21-25",
    "26-30",
    "31+"
  ],
  ageRanges: ["18-35", "36-49", "50-64", "65+"],

  volumeSteps: [
    0.1, // 0%, -20db
    0.11220184543019636, // 5%, -19db
    0.12589254117941673, // 10%, -18db
    0.14125375446227545, // 15%, -17db
    0.15848931924611134, // 20%, -16db
    0.1778279410038923, // 25%, -15db
    0.19952623149688797, // 30%, -14db
    0.22387211385683395, // 35%, -13db
    0.251188643150958, // 40%, -12db
    0.28183829312644537, // 45%, -11db
    0.31622776601683794, // 50%, -10db
    0.35481338923357547, // 55%, -9db
    0.3981071705534972, // 60%, -8db
    0.44668359215096315, // 65%, -7db
    0.5011872336272722, // 70%, -6db
    0.5623413251903491, // 75%, -5db
    0.6309573444801932, // 80%, -4db
    0.7079457843841379, // 85%, -3db
    0.7943282347242815, // 90%, -2db
    0.8912509381337456, // 95%, -1db
    1
  ], // 100%, 0db

  percentToVolume: percent => {
    // convenience function to convert from percentage to html5 audio volume setting from volumeSteps array

    if (percent === null) percent = config.defaultAudioLevelPercentage;

    percent = parseInt(percent, 10);

    if (percent < 0) percent = 0;

    if (percent > 100) percent = 100;

    const x = percent / 100;
    const i = Math.round((config.volumeSteps.length - 1) * x);

    //console.log('percentToVolume: percent = ' + percent + ' volume = ' + config.volumeSteps[i])

    return config.volumeSteps[i];
  },

  defaultAudioLevelPercentage: 50,

  // marketo config

  env: process.env.REACT_APP_MARKETO_ENV, // SIT, UAT or PRD

  marketo: {
    SIT: {
      url: "//app-sn05.marketo.com",
      munchkinId: "483-RNP-618",
      formId: 12055,
      formIdAPAC: 12055,
      formIdCAM: 11365,
      formIdEMEA: 11952
    },

    UAT: {
      url: "//app-lon09.marketo.com",
      munchkinId: "590-TVG-689",
      formId: 12116,
      formIdAPAC: 12116,
      formIdCAM: 11365,
      formIdEMEA: 11952
    },

    PRD: {
      url: "//app-lon04.marketo.com",
      munchkinId: "087-KYD-238",
      formId: 12116,
      formIdAPAC: 12116,
      formIdCAM: 11365,
      formIdEMEA: 11952
    }
  },

  getImage: function(image) {
    return config.imagePath + image;
  }
};

export default config;
