import React, { Component } from "react";
import { Text } from "react-easy-i18n";
import NextButton from "../../nextButton/nextButton";
import { capitalizeFirstLetter } from "../../../common/string";
import config from "../../../common/config";
import localise from "../../../common/localise";
import PropTypes from "prop-types";

import "./stop.scss";

export default class TestStop extends Component {
  onNext() {
    if (this.props.onNext) this.props.onNext();
  }

  render() {
    const { data } = this.props;
    const t = "test.stop";
    const firstName =
      data && data.firstName ? capitalizeFirstLetter(data.firstName) : null;
      const country1 = localise.getString("appSettings.country1");
      const phone1 = localise.getString("appSettings.phone1");
      const phoneTelLink1 = phone1.replace(/\s+/g, ""); //Remove white spaces from phone number
      const country2 = localise.getString("appSettings.country2");
      const phone2 = localise.getString("appSettings.phone2");
      const phoneTelLink2 = phone2.replace(/\s+/g, ""); //Remove white spaces from phone number
    const email = localise.getString("appSettings.email");

    return (
      <section className="step stop" aria-label="stop test">
        <h2>
          {firstName} <Text className="t1" text={`${t}.t1`} />
        </h2>
        <div>
          <img
            src={config.getImage("Mona-standard.svg")}
            className="icon mona-lisa "
            alt="mona info"
          />
        </div>
        <p>
          <Text className="t2" text={`${t}.t2`} />
        </p>
        <NextButton
          label={t + ".nextButton"}
          onClick={this.onNext.bind(this)}
        ></NextButton>
        <p className="bold">
          <Text className="t3" text={`${t}.t3`} />
        </p>
        <div className="contact-info">
          <div className="speakdiv">
            <img
              className="icon small"
              src={config.getImage("Icon_email.svg")}
              alt="email"
            />
            <div>
              <Text text={`${t}.email`} className="email" />
              <a
                href={`mailto:${email}?subject=Contact Concierge`}
                className="btn btn-link"
              >
                {email}
              </a>
            </div>
          </div>
          <div className="speakdiv">
            <img
              className="icon small"
              src={config.getImage("Icon_phone.svg")}
              alt="phone"
            />
            <div className="call-parent">
              <Text text={`${t}.call`} className="call" />
              <a href={`tel:${phoneTelLink1}`} className="btn btn-link">
                {phone1}
              </a>
              <span className="call">{country1}</span>
              
              <a href={`tel:${phoneTelLink2}`} className="btn btn-link">
                {phone2}
              </a>
              <span className="call">{country2}</span>
              <div className="smallfont">
                <Text text={`${t}.calltext`} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

TestStop.propTypes = {
  onNext: PropTypes.func,
  data: PropTypes.object
};
