import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import NextButton from '../../nextButton/nextButton'
import config from '../../../common/config'
import PropTypes from 'prop-types'

import './pause.scss';

export default class TestPause extends Component {

  onNext() {
    if (this.props.onNext)
      this.props.onNext()
  }

  render() {

    const t = 'test.pause'

    return (
      <section className="step pause" aria-label='region'>
        <div>  
          <img src={config.getImage('Mona-standard.svg')} className='icon mona-lisa ' alt='mona info' />
        </div>
        <h1><Text className='t1' text={`${t}.t1`} /></h1>
        <p><Text className='t2' text={`${t}.t2`} /></p>
        <NextButton label={t + '.nextButton'} onClick={this.onNext.bind(this)}></NextButton>
      </section>
    )
  }
}

TestPause.propTypes = {
  onNext: PropTypes.func
}
