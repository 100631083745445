import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import config from '../../../common/config'
import NextButton from '../../nextButton/nextButton'
import { capitalizeFirstLetter } from '../../../common/string'
import PropTypes from 'prop-types'

import './breather.scss';

export default class TestBreather extends Component {

  onNext() {

    if (this.props.onNext)
      this.props.onNext()
  }

  render() {

    const { data, question } = this.props;
    const firstName = (data && data.firstName ) ? capitalizeFirstLetter(data.firstName) : null
    const t = 'test.breather'
    let text = <Text className='t1a' text={`${t}.t1a`}/>

    if (question === config.almostThereQuestion1 || question === config.almostThereQuestion2)
      text = <Text className='t1b' text={`${t}.t1b`}/>

    return (

    <section className="step breather" aria-label='region'>
      <div>  
        <img src={config.getImage('Mona-standard.svg')} className='icon mona-lisa ' alt='mona info' />
      </div>
      <h1 className="mar-b-30 mar-t-10">
      <Text className='t1' text={`${t}.t1`}/>
      {firstName} 
      ,
      {text}
      </h1>
      <p><Text className='t2 mar-b-30' text={`${t}.t2`} /><br/><Text className='t2 mar-b-30' text={`${t}.t3`} /></p>
      <NextButton label={t + '.nextButton'} onClick={this.onNext.bind(this)}></NextButton>
    </section>
    )
  }
}

TestBreather.propTypes = {
  onNext: PropTypes.func,
  data: PropTypes.object,
  question: PropTypes.number
}