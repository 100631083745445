import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import VolumeControl from './volumeControl/volumeControl'
import localise from '../../../common/localise'
import config from '../../../common/config'
import NextButton from '../../nextButton/nextButton'
import PropTypes from 'prop-types'

import './index.scss';

export default class SetupVolume extends Component { 

  constructor(props) {

    super(props)
    
    this.state = {
      volume: (props.data && props.data.audioLevelPercentage) ? props.data.audioLevelPercentage : config.defaultAudioLevelPercentage, 
      cantHear: false 
    }
  }

  onNext() {

    const { volume } = this.state

    if (this.props.onNext)
      this.props.onNext(volume)
  }

  onVolumeChange(volume) {

    this.setState({volume: volume})
  }

  onCantHear() {

    this.setState({cantHear: true})
  }

  render() {

    const { setup } = this.props

    const t = 'setup.volume'

        // html5 audio control fails badly if no file given

        let file

        if (setup && setup.audioFileUrl && setup.calibrationFiles && setup.calibrationFiles.audioFiles && setup.calibrationFiles.audioFiles.length > 0)
          file = setup.audioFileUrl + '/' + setup.calibrationFiles.audioFiles[0].file

        const { volume, cantHear } = this.state 

        const specialText = localise.getString(t + '.t4')

        return (

          <section className="step volume" aria-label='volume page'>

          <h1>
          <Text text={`${t}.t1`} />
          </h1>

          <p className='not-ios'>
          <Text text={`${t}.t2`} />
          
          <br/>
          
          </p>

          <Text className='ios' text={`${t}.iosText`} />

          <VolumeControl file={file} value={volume} onChange={this.onVolumeChange.bind(this)} />

          <NextButton label={t + '.nextButton'} onClick={this.onNext.bind(this)} ></NextButton>

          <button className={`btn btn-link btn-help ${cantHear ? "disabled" : ""}`} disabled={cantHear} onClick={this.onCantHear.bind(this)} aria-label='cant hear' title={localise.getString(`${t}.btn1`)} >
          <Text text={`${t}.btn1`} className="help-text" />
          </button>

          {
            (cantHear) ?

            <article className='help-text-container'>
            
            <img src={config.getImage('Mona-info.svg')} className='icon' alt='mona info' />

            <span>

            {specialText}
            
            <a className='btn btn-link' 
            href='docs/Cochlear device guide.pdf'
            role="button"
            target="_blank" 
            rel="noopener noreferrer" 
            title={ localise.getString(`${t}.t5`) }
            >
            <Text text={`${t}.t5`} /></a>
            </span>
            

            </article>

            :

            null
          }

          </section>
          )
        }
      }

      SetupVolume.propTypes = {
        data: PropTypes.object,
        setup: PropTypes.object,
        onNext: PropTypes.func
      }