import React, { Component } from 'react'
import { Text } from 'react-easy-i18n';
import localise from '../../common/localise'
import PropTypes from 'prop-types'

import './nextButton.scss';

export default class NextButton extends Component {

  onClick(e) {

    if (this.props.onClick)
      this.props.onClick()
  }

  render() {

    let { link, label, className } = this.props

    if (!className)
      className = ''

    const ariaLabel =  (label) ? localise.getString(label) : 'next button'

    if (link) {

      return <a href={link} 
      className='btn btn-next' 
      target='_blank' 
      rel="noopener noreferrer" 
      aria-label='next button'>
      
    {/* {this.props.children} */}

    <Text text={label} />

    <div className='arrow' />
    </a>
      }
      else {
        return (
        <button aria-label={ariaLabel} type="button" className={`btn btn-next ${className}`} onClick={this.onClick.bind(this)}>
        <Text text={label} />
      {/* {this.props.children} */}
      <span className='arrow'></span>
      </button>
      )
    }
  }
}

NextButton.propTypes = {
  onNext: PropTypes.func,
  link: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string
}