import React, { Component } from 'react'
import { Text } from 'react-easy-i18n'
import SoundButton from '../../soundButton/soundButton'
import config from '../../../common/config'
import { capitalizeFirstLetter } from '../../../common/string'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ClickableElement from '../../clickableElement/clickableElement'

import './chooseSound.scss'

export class TouchLink extends ClickableElement {

  render() {
      
    const otherProps = _.omit(this.props, ['onClick', 'type']);

    const t = 'practice.chooseSound'

    return (
      <button
          onClick={e => this.onElementClicked(e)}
          type="button"
          {...otherProps}>

          <Text text={`${t}.btn2`} />

      </button>
    );
  }
}

export default class ChooseSound extends Component {

  constructor(props) {

      super(props)

      // default values

      this.SAMPLE_DURATION = 1.200       // length of a sample in seconds
      this.INTRO_DURATION = 0.5        // time before first sound seconds
      this.BUTTON_NO = 3

      const { files } = props

      // get sound duration from setup

      if (files) {

        const options = files.audioOptions

        this.SAMPLE_DURATION = options[0].duration
        this.INTRO_DURATION = options[0].start
      }

      // only include variable in state cause a redraw

      this.state = { selectedButtonId: -1 }    // no sound button active initially, links disabled

      this.audioPositionTimerId = null
      this.bufferTimerId = null

      this.audioLoaded = false
      this.played = false
      this.timestamp = 0
  }

  initState() {

    this.setState({ selectedButtonId: -1})

    this.killTimers()

    this.audioLoaded = false
    this.played = false
    this.timestamp = 0
  }

  initAudio() {

    const { data } = this.props

    let audio = this.refs.audio

    if (audio) {
      audio.volume = config.percentToVolume(data.audioLevelPercentage)
    }
  }

  getActiveSoundId() {

    let audio = this.refs.audio

    let activeSoundId = -1

    let t = this.props.currentTime

    if (audio)
      t = audio.currentTime

    if (t >= (this.INTRO_DURATION + 3 * this.SAMPLE_DURATION) )
      activeSoundId = 3
    else
    if (t >= (this.INTRO_DURATION + 2 * this.SAMPLE_DURATION) && t <= (this.INTRO_DURATION + 3 * this.SAMPLE_DURATION) )
      activeSoundId = 2
    else
    if (t >= (this.INTRO_DURATION + this.SAMPLE_DURATION) && t <= (this.INTRO_DURATION + 2 * this.SAMPLE_DURATION) )
      activeSoundId = 1
    else
    if (t >= this.INTRO_DURATION && t <= (this.INTRO_DURATION + this.SAMPLE_DURATION) )
      activeSoundId = 0
    
    return activeSoundId
  }

  startAudioTimer() {

    let audio = this.refs.audio

    let self = this

    if (audio) {

      audio.onended = function() {

        // kill the timer

        clearInterval(self.audioPositionTimerId)
        self.audioPositionTimerId = null

        // signal a redraw

        self.setState({ activeSoundId: self.BUTTON_NO })

        // get the timestamp

        self.timestamp = new Date().getTime()
      }

      if (!this.audioPositionTimerId) {

        this.audioPositionTimerId = setInterval(() => {

          const x = this.getActiveSoundId()

          if (x !== this.state.activeSoundId)
            this.setState({activeSoundId: x})
    
        }, 200)
      }
    }
  }

  startBufferTimer() {

    // wait before starting playback

    if (this.bufferTimerId)   // timer already running
      return

    const { replay } = this.props

    this.bufferTimerId = setTimeout(() => {

      this.bufferTimerId = null

      if (this.audioLoaded) {
        this.playSound()
      }

    }, replay ? config.replayBufferTime : config.bufferTime)
  }

  killTimers() {

    // kill all timers

    if (this.audioPositionTimerId) {
      clearInterval(this.audioPositionTimerId) 
      this.audioPositionTimerId = null
    }

    if (this.bufferTimerId) {
      clearTimeout(this.bufferTimerId) 
      this.bufferTimerId = null
    }
  }

  //------------------------------------------------------

  init() {

    this.initState()
    this.initAudio()
    this.startBufferTimer()

    // for IOS devices we need to call audio.load

    let audio = this.refs.audio

    if (audio)
      audio.load()
  }

  componentDidMount() {

    this.init()
  }

  componentWillUnmount() {

    this.killTimers()
  }

  componentDidUpdate(prevProps) {

    if (this.props.question !== prevProps.question || this.props.repeats !== prevProps.repeats) {

      this.init()

      if (this.props.repeats !== prevProps.repeats)
        this.audioLoaded = true
    }
  }

  //------------------------------------------------------

  onNext(id) {

    if (this.props.onNext) {

      // cant click until all timers have finished

      //if (!this.audioPositionTimerId && !this.bufferTimerId) {

        let t = new Date().getTime()
  
        t -= this.timestamp    // subtract time since test question started
        t /= 1000         // convert to seconds

        this.setState({selectedButtonId: id})
  
        this.props.onNext(id, t)
      }
    //}
  }

  onRepeat() {

    if (this.props.onRepeat)
      this.props.onRepeat()
  }

  //-----------------------------------------------------------------------------------------
  // audio specific functions

  playSound() {

    // assumes audio is loaded

    let audio = this.refs.audio

    if (audio) {

      //audio.load()
      audio.currentTime = 0   // rewind
      audio.play()

      this.startAudioTimer()

      this.played = true
    }
  }

  onAudioLoaded() {

    // we need to handle the case where the audio finishes loading after the buffer timer has finished
    // this could occur on a slow internet connection

    // due to state changes the component is rendered multiple times
    // and this function may execute more than once per sound

    if (!this.audioLoaded) {

      this.audioLoaded = true
  
      if ( !this.bufferTimerId && !this.played) {

        // buffer timer has finished and the audio has not yet been played
  
        this.playSound()
      }
    }
  }

  onAudioError(msg) {

    this.initState()

    if (this.props.onError)
      this.props.onError('error loading audio file')
  }

  //-----------------------------------------------------------------------------------------
  // code diverges between practice and test below

  onUnsure() {

    // submit an unanswered question

    //this.setState({selectedButtonId: 0})

    this.onNext(0)
  }

  render() {

    const { selectedButtonId } = this.state

    const { question, data, setup, files } = this.props

    let activeSoundId = this.getActiveSoundId()

   // if sound is playing or not yet played links are disabled

    let disableLinks = (activeSoundId < this.BUTTON_NO)

     // if we have selected a sound disable links and bettons so user knows something has happend and not to press again

    if (selectedButtonId >= 0) {
      disableLinks = true
      activeSoundId = -1
    }

    const firstName = (data && data.firstName ) ? capitalizeFirstLetter(data.firstName) : null

    var buttons = Array(this.BUTTON_NO).fill(0)

    let file = ''
    let n = 0

    if (files && files.audioFiles && files.audioFiles.length > 0) {
      file = setup.audioFileUrl + '/' + ((question < files.audioFiles.length) ? files.audioFiles[question].file : files.audioFiles[0].file)
      n = files.audioFiles.length
    }

    const t = 'practice.chooseSound'

    return (
        <div className='step choose-sound'>

            <p><Text className='t1' text={`${t}.t1`}/> {question+1} / {n} </p>

            <div>
             <img src={config.getImage('Mona-standard.svg')} className='icon mona-lisa ' alt='mona info' />
            </div>

            <h2><Text className='t2' text={`${t}.t2`} /></h2>

            <h3 className="bold">{firstName}<Text className='t3' text={`${t}.t3`} /></h3>
              <Text text={`${t}.t4`}  className="t4"/>

            <section className='buttons' aria-label='region'>

              {
                buttons.map((id, i) => {

                  let buttonState = SoundButton.UNPLAYED

                  if (i === activeSoundId)
                    buttonState = SoundButton.ACTIVE
                  else
                  if (activeSoundId > -1 && i < activeSoundId)
                    buttonState = SoundButton.PLAYED
                
                  return <SoundButton key={i} 
                        id={i+1} 
                        selected={selectedButtonId === i+1} 
                        buttonState={buttonState} 
                        canClick={activeSoundId === this.BUTTON_NO}
                        onClick={this.onNext.bind(this)} />                  
                })
              }

            </section>

              <audio ref='audio' 
                    src={file}
                    autoPlay={false}
                    preload="auto"
                    onError={this.onAudioError.bind(this)} 
                    onCanPlayThrough={this.onAudioLoaded.bind(this)}>
                    </audio>

            <article className={`links ${disableLinks? "disabled": ""}`} >

              <button className='btn btn-link btn-repeat' disabled={disableLinks} onClick={this.onRepeat.bind(this)}>
                  <img src={disableLinks ? config.getImage('Icon_repeat_disabled.svg') : config.getImage('Icon_repeat.svg')} alt='repeat' />
                  <Text text={`${t}.btn1`} />
              </button>

              <span className='col'>|</span>

              { <TouchLink id='btn2' className='btn btn-link btn-unsure' disabled={disableLinks} onClick={this.onUnsure.bind(this)} /> }

            </article>
          

        </div>
        
    )
  }
}


ChooseSound.propTypes = {
  files: PropTypes.object,
  data: PropTypes.object,
  setup: PropTypes.object,
  currentTime: PropTypes.number,
  replay: PropTypes.bool,
  question: PropTypes.number.isRequired,
  repeats: PropTypes.number,

  onNext: PropTypes.func,
  onError: PropTypes.func,
  onRepeat: PropTypes.func,
}