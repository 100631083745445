import config from '../common/config'
import localise from "../common/localise";
export default class marketoAPI {

    static REGISTER = 1;
    static TEST_RESULT = 2;
    static CTA = 3;

    static mapData(type, user, cIScreenerID, data, utm) {

        // 3 types of marketo submissions:
    
        // 1: registration
        // 2: test result
        // 3: cta

        // map marketo fields
    
        let mappedData
        const Single_Sided_Deafness__c = (user.howManyEars.toLowerCase() === 'one ear') ? 'True' : 'False'

        const Hearing_Aid_User__c = ((user.hearingAidsHowOften.toLowerCase() === 'always' || user.hearingAidsHowOften.toLowerCase() === 'sometimes')) ? 'Yes' : 'No'
        const Marketing_Consent__c = () => {
          // Add countries that require marketing consent checkbox
          const {marketingConsent, country} = user;
          const marketingConsentCountries = ["Canada","United Kingdom","Ireland"];
          const consentRequired = marketingConsentCountries.indexOf(country) > -1 
          if (!consentRequired) {
            return true;
          }
          return marketingConsent;
        }
    
        switch (parseInt(type, 10)) {
          case marketoAPI.REGISTER:
          default:
              let mobileno = ""
              let phoneno = user.Phone
          if(user.Phonetype === "2")
          {
            mobileno = user.Phone
            phoneno = ""
          }

          let region = localise.getString("appSettings.region")
          if (region === "CAM")
          {
            mappedData = {
    
                // id fields:
  
                cIScreenerID: cIScreenerID,
  
                Single_Sided_Deafness__c: Single_Sided_Deafness__c,
                Best_description_of_the_candidate__c: user.bestDescription,
                Hearing_Aid_User__c: Hearing_Aid_User__c,
                Members_Approximate_Age__c: user.ageRange,
                FirstName: user.firstName,
                LastName: user.lastName,
                Email: user.email,
                PostalCode: user.postcode,
                Phone: phoneno,
                MobilePhone: mobileno,
                Country: user.country,
  
                Privacy_Consent__c: 'Given',
                Marketing_Consent__c: Marketing_Consent__c() ? 'Given' : '',
                LeadSource: 'Web Referral',
                Lead_Source_Detail__c: 'CI Screener',
                Region__c: 'CAM',
                RecordTypeId_lead: '012240000002fJKAAY',              
                utm_source__c: utm.source,
                utm_campaign__c: utm.campaign,
                utm_content__c: utm.content,
                utm_medium__c: utm.medium,
                utm_term__c: utm.term,
                Quiz_Result: 1,
                Marketing_Consent_Email__c: Marketing_Consent__c() ? 'Given' : '',
                Marketing_Consent_Events__c: Marketing_Consent__c() ? 'Given' : '',
                Marketing_Consent_Newsletters__c: Marketing_Consent__c() ? 'Given' : '',
                Marketing_Consent_Product_Info__c: Marketing_Consent__c() ? 'Given' : '',
              }
              if (user.country === 'Canada') {
                mappedData.Marketing_Consent_Survey__c = Marketing_Consent__c() ? 'Given' : ''
              }
          }
          else if (region === "EMEA") {
            mappedData = {
      
                // id fields:

                cIScreenerID: cIScreenerID,

                Single_Sided_Deafness__c: Single_Sided_Deafness__c,
                Best_description_of_the_candidate__c: user.bestDescription,                
                Hearing_Aid_User__c: Hearing_Aid_User__c,
                Members_Approximate_Age__c: user.ageRange,
                FirstName: user.firstName,
                LastName: user.lastName,
                Email: user.email,
                PostalCode: user.postcode,
                Country: user.country,

                Privacy_Consent__c: 'Given',
                Marketing_Consent__c: Marketing_Consent__c() ? 'Given' : '',
                Marketing_Consent_Email__c: Marketing_Consent__c() ? 'Given' : '',
                Marketing_Consent_Survey__c: Marketing_Consent__c() ? 'Given' : '',
                Preferred_Language__c: 'English',
                LeadSource: 'Web Referral',
                Lead_Source_Detail__c: 'CI Screener',
                Region__c: 'EMEA',
                RecordTypeId_lead: '012240000002fJKAAY',              
                utm_source__c: utm.source,
                utm_campaign__c: utm.campaign,
                utm_content__c: utm.content,
                utm_medium__c: utm.medium,
                utm_term__c: utm.term,
                Quiz_Result: 1
            }
          }
          else
          {
          mappedData = {
    
              // id fields:

              cIScreenerID: cIScreenerID,

              Single_Sided_Deafness__c: Single_Sided_Deafness__c,
              Hearing_Aid_User__c: Hearing_Aid_User__c,
              Members_Approximate_Age__c: user.ageRange,
              FirstName: user.firstName,
              LastName: user.lastName,
              Email: user.email,
              PostalCode: user.postcode,
              Phone: phoneno,
              MobilePhone: mobileno,
              Country: user.country,

              Privacy_Consent__c: 'Given',
              Marketing_Consent__c: Marketing_Consent__c() ? 'Given' : '',
              LeadSource: 'Web Referral',
              Lead_Source_Detail__c: 'CI Screener',
              Region__c: 'APAC',
              RecordTypeId_lead: '012240000002fJKAAY',              
              utm_source__c: utm.source,
              utm_campaign__c: utm.campaign,
              utm_content__c: utm.content,
              utm_medium__c: utm.medium,
              utm_term__c: utm.term,
              Quiz_Result: 1
            }
        }
            break;
    
          case marketoAPI.TEST_RESULT: 
            
                // TEST RESULT
                // detailedResults: {candidacy: 3, scorePercentage: 21, testDetails: "<somehtml></somehtml>"}
          
            mappedData = {

                FirstName: user.firstName,
                LastName: user.lastName,
                Email: user.email,

                CI_Screener_Result__c: data.scorePercentage ,
                cIScreenerclass: data.candidacy,
                cIScreenerdetails: data.testDetails,
                Quiz_Result: 2
            }
            break;

          case marketoAPI.CTA: 

            // CTA
        
            mappedData = {

                CI_Screener_CTA__c: data.CI_Screener_CTA__c,
                FirstName: user.firstName,
                LastName: user.lastName,
                Email: user.email,
            }
            break;
        }
    
        return mappedData
      }


    static sendNonBlocking(type, user, cIScreenerID, data, utm) {

        // marketo forms API does not have an form.onError function
        // if I returned a promise from the function and the call to form.submit is unsuccessful we would wait forever
        // easiest solution is dont wait for response from form.submit
        // if it fails we dont catch it

        const functionName = "marketoAPI.sendNonBlocking: "

        // map data to marketo fields

        const mappedData = marketoAPI.mapData(type, user, cIScreenerID, data, utm)

        if (!mappedData.FirstName || !mappedData.FirstName  === '' ||
            !mappedData.LastName || !mappedData.LastName === '' ||
            !mappedData.Email || !mappedData.Email === '') {
            console.log(functionName + 'missing fields in data')
            return 'invalid data'
        }

        if (!window.MktoForms2) {
            console.log(functionName, 'window.MktoForms2 does not exist')
            return 'window.MktoForms2 does not exist'
        }

        console.log(functionName, 'json to send = ', mappedData)
            
        const env = config.marketo[config.env]
        let localformID = "";
        const region = localise.getString("appSettings.region");
        switch (region) {
          case "APAC":
            localformID = env.formIdAPAC;
            break;
          case "CAM":
            localformID = env.formIdCAM;
            break;
          case "EMEA":
            localformID = env.formIdEMEA;
            break;
          default:
            localformID = env.formId;
            break;
        }

        window.MktoForms2.loadForm(env.url, env.munchkinId, localformID, (form) => {

            

            form.addHiddenFields(mappedData)

            if (form.submittable()) {

                form
                // Triggers the form’s submit event. This will start the from submit flow, performing validation,
                // firing any onSubmit events, submitting the form, and firing any onSuccess events if form submission was successful.
                .submit()
        
                // Adds a callback that will be called when the form has been successfully submitted but before the lead is forwarded
                // to the follow up page. Can be used to prevent the lead from being forwarded to the follow up page after successful submission.
        
                .onSuccess((response) => {

                    return false // Cancel the follow up page action
                })
            }
        })

        return true
    }
}