import React, { Component } from "react";
import { Text } from "react-easy-i18n";
import { capitalizeFirstLetter } from "../../../common/string";
import config from "../../../common/config";
import localise from "../../../common/localise";
import PropTypes from "prop-types";
import NextButton from "../../nextButton/nextButton";

import "./unsure.scss";

export default class TestUnsure extends Component {
  onNext() {
    if (this.props.onNext) this.props.onNext();
  }

  onRepeatPractice() {
    if (this.props.onRepeatPractice) this.props.onRepeatPractice();
  }

  render() {
    const { data } = this.props;
    const t = "test.unsure";
    const firstName =
      data && data.firstName ? capitalizeFirstLetter(data.firstName) : null;
      const country1 = localise.getString("appSettings.country1");
      const phone1 = localise.getString("appSettings.phone1");
      const phoneTelLink1 = phone1.replace(/\s+/g, ""); //Remove white spaces from phone number
      const country2 = localise.getString("appSettings.country2");
      const phone2 = localise.getString("appSettings.phone2");
      const phoneTelLink2 = phone2.replace(/\s+/g, ""); //Remove white spaces from phone number
    const email = localise.getString("appSettings.email");

    return (
      <section className="step unsure" aria-label="unsure">
        <article className="panel">
          <div className="info1">
            <h2>
              {firstName}
              <Text className="t1" text={`${t}.t1`} />
            </h2>
            <div>
              <img
                src={config.getImage("Mona-standard.svg")}
                className="icon mona-lisa "
                alt="mona info"
              />
            </div>
            <p className="mar-t-10">
              <Text className="t2" text={`${t}.t2`} />
            </p>
            <p>
              <Text className="t3 bold" text={`${t}.t3`} />
            </p>
            <div className="bottom">
              <div>
                <NextButton
                  label={t + ".continue.btn1"}
                  onClick={this.onNext.bind(this)}
                ></NextButton>
              </div>
              <div>
                <button
                  className="btn btn-link btn-repeat"
                  onClick={this.onRepeatPractice.bind(this)}
                >
                  <Text text={`${t}.continue.btn2`} />
                  <div className="arrow"></div>
                </button>
              </div>
            </div>
          </div>
        </article>
        <section className="row bottom" aria-label="bottom section">
          {/* <section className='col-sm panel panel-border'>
                  <p className="bold"><Text className='continue t1' text={`${t}.continue.t1`} /></p>
                  <p className="smallfont"><Text className='continue t2' text={`${t}.continue.t2`} /></p>
                </section> */}
          <section className="col-sm panel panel-border">
            <article className="links">
              <h3 className="bold">
                <Text className="t1" text={`${t}.speakToSomeone.t1`} />
              </h3>
              <p>
                <Text className="t2" text={`${t}.speakToSomeone.t2`} />
                {/* <Text className='t2' text={`${t}.speakToSomeone.t2b`} /> */}
              </p>
              <div className="contact-info">
                <div className="speakdiv">
                  <img
                    className="icon small"
                    src={config.getImage("Icon_email.svg")}
                    alt="email"
                  />
                  <div>
                    <Text
                      text={`${t}.speakToSomeone.email`}
                      className="email"
                    />
                    <a
                      href={`mailto:${email}?subject=Contact Concierge`}
                      className="btn btn-link"
                    >
                      {email}
                    </a>
                  </div>
                </div>
                <div className="speakdiv">
                  <img
                    className="icon small"
                    src={config.getImage("Icon_phone.svg")}
                    alt="phone"
                  />
                  <div className="call-parent">
                    <Text text={`${t}.speakToSomeone.call`} className="call" />
                    <a href={`tel:${phoneTelLink1}`} className="btn btn-link">
                      {phone1}
                    </a>
                    {country1}
                    <a href={`tel:${phoneTelLink2}`} className="btn btn-link">
                      {phone2}
                    </a>
                    {country2}
                    <div className="smallfont">
                      <Text text={`${t}.speakToSomeone.calltext`} />
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>
        </section>
      </section>
    );
  }
}

TestUnsure.propTypes = {
  onNext: PropTypes.func,
  onRepeatPractice: PropTypes.func,
  data: PropTypes.object
};
