import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import NextButton from '../../nextButton/nextButton'

import './thanks.scss';

export default class PracticeThanks extends Component {

  onNext() {

    if (this.props.onNext)
      this.props.onNext()
  }

  render() {

    const t = 'practice.thanks'

    return (
     
     
      <div className="step thanks">
      
      <h2><Text className='t1' text={`${t}.t1`}/></h2>
      
      <p><Text text={`${t}.t2`} /><br/><Text className='t3' text={`${t}.t3`} /></p>

      <p></p>

    {/* autoFocus={true} */}

    <NextButton label={t + ".nextButton"} onClick={this.onNext.bind(this)}></NextButton>
    </div>

    )
  }
}
