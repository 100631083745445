export function pad(num, size) {

	var s = num+"";
	while (s.length < size) s = "0" + s;
	return s;
}

export function capitalizeFirstLetter(string) {

	if (string && string.length > 0)
		return string.charAt(0).toUpperCase() + string.slice(1);

	return string
}