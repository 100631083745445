import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import config from '../../../common/config'
import NextButton from '../../nextButton/nextButton'
import PropTypes from 'prop-types'

import './intro.scss';


export default class HomeIntro extends Component {

  onNext() {

    if (this.props.onNext)
      this.props.onNext()
  }

  render() {

    const t = 'home.intro'

    return (
      <section className='intro' aria-label='home intro'>
      
      <section className='top' aria-label='top section'>

      <div className='panel-container'>

      <div className='panel'>

      <article aria-label='text block'>

      <h1><Text text={`${t}.heading`}  /></h1>

      <p><Text text={`${t}.t1`} /></p>

      <p><Text text={`${t}.t2`} /></p>

      <p><Text text={`${t}.t3`} /></p>

      <NextButton label={t + ".nextButton"} onClick={this.onNext.bind(this)}></NextButton>

      </article>

    {/* autoFocus={true}  */}

    

    </div> 
    
    </div>

    </section>

    <section className='container-fluid bottom' aria-label='bottom section'>

    <Text text={`${t}.t4`} className="smallfont bold" />

    <article className='disclaimer' aria-label='text block'>

    <p style={{whiteSpace: 'pre-wrap'}}><Text text={`${t}.t5`} className="smallfont" /><br/><Text text={`${t}.t6`} className="smallfont bold" /></p>

    <a href="https://www.nal.gov.au/project/language-independent-test-of-candidacy-for-cochlear-implants/" target="_blank" rel="noopener noreferrer" title=""><img className='nal hide-desktop' src={ config.getImage('NAL-desktop.png') } alt='NAL logo' /></a>
    <a href="https://www.nal.gov.au/project/language-independent-test-of-candidacy-for-cochlear-implants/" target="_blank" rel="noopener noreferrer" title=""><img className='nal hide-mobile' src={ config.getImage('NAL-mobile.png') } alt='NAL logo' /> </a>
    </article>

    </section>
    
    </section>
    )
  }
}

HomeIntro.propTypes = {
  onNext: PropTypes.func
}