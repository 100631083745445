import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import config from '../../../common/config'
import NextButton from '../../nextButton/nextButton'
import { capitalizeFirstLetter } from '../../../common/string'
import PropTypes from 'prop-types'

import './intro.scss';

export default class ResultIntro extends Component {

  onNext() {

    if (this.props.onNext)
      this.props.onNext()
  }

  render() {

    //const functionName = 'ResultIntro.render: '
    const { data, score } = this.props
    //console.log(functionName, score)
    const t = 'result.intro'
    const firstName = (data && data.firstName ) ? capitalizeFirstLetter(data.firstName) : null
    const email = (data && data.email) ? data.email : null
    let t1 = 'notT1'
    let t2 = 'notT2'
    let t3 = 'notT3'
    let t4 = 'notT4'
    let t5 = 'notT5'
    let visual = 'Unlikely.png'

    if (score && score.detailedResults) {

      switch (parseInt(score.detailedResults.candidacy, 10)) {

        case config.DEFINITE_CANDIDATE: 
        t1 = 'definiteT1'
        t2 = 'definiteT2'
        t3 = 'definiteT3'
        t4 = 'definiteT4'
        t5 = 'definiteT5'
        visual = 'Strong.png'
        break;

        case config.LIKELY_CANDIDATE: 
        t1 = 'likelyT1'; 
        t2 = 'likelyT2'; 
        t3 = 'likelyT3';
        t4 = 'likelyT4';
        t5 = 'likelyT5';
        visual = 'Likely.png'
        break;

        case config.POSSIBLE_CANDIDATE:  
        t1 = 'possibleT1'; 
        t2 = 'possibleT2'; 
        t3 = 'possibleT3'; 
        t4 = 'possibleT4'; 
        t5 = 'possibleT5'; 
        visual = 'Possible.png'
        break;

        case config.NOT_A_CANDIDATE:  
        default:
        t1 = 'notT1'; 
        t2 = 'notT2'; 
        t3 = 'notT3'; 
        t4 = 'notT4';
        t5 = 'notT5';
        visual = 'Unlikely.png'
        break;
      }
    }

    return (
      <section className="step intro" aria-label='intro'>
        <h4 className="mar-b-30"> <Text className='t1' text={`${t}.t1`} />{firstName}.</h4>
        <img className='icon' src={config.getImage('Icon_email.svg')} alt='email' />
        <p className="mar-t-10" ><Text className='t2' text={`${t}.t2`} /> 
        { email } 
        </p>
        <section className='panel' aria-label='panel'>
        <div className='content row'>
        <img className='col-md-4 earimg' src={config.getImage(visual)} alt='result' />
        <article className='text-box col-md-8'>
        <h2><Text text={`${t}.${t1}`} /><sup className="smallfont">*†</sup ></h2>
        <p ><Text text={`${t}.${t2}`} /></p>
        <p className="bold" ><Text text={`${t}.${t3}`} /></p>
      {/* autoFocus={true}  */}
      <NextButton label={t + ".btn1"} onClick={this.onNext.bind(this)}></NextButton>
      <p className="smallfont mar-t-10"><Text text={`${t}.${t4}`} /></p>
      <p className="smallfont mar-t-10"><Text text={`${t}.${t5}`} /></p>

      </article>
      </div>
      </section>
    </section>
    )
  }
}

ResultIntro.propTypes = {
  score: PropTypes.object,
  data: PropTypes.object,
  onNext: PropTypes.func,
}