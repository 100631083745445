import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import config from '../../../common/config'
import NextButton from '../../nextButton/nextButton'
import PropTypes from 'prop-types'

import './index.scss';

export default class SetupConfirmComfort extends Component {

  onNext() {

    if (this.props.onNext)
      this.props.onNext()
  }

  render() {

    const t = 'setup.confirmComfort'

    return (
      <div className="step confirm-comfort">
        <img src={config.getImage('Icon_clock.svg')} alt='clock' />
        <h1><Text text={`${t}.t1`} /></h1>
        {/* autoFocus={true}  */}
        <NextButton label={t + ".nextButton"} onClick={this.onNext.bind(this)}></NextButton>
      </div>
    )
  }
}

SetupConfirmComfort.propTypes = {
  onNext: PropTypes.func
}