import React, { Component } from "react";
import { Text } from "react-easy-i18n";
import config from "../../../common/config";
import NextButton from "../../nextButton/nextButton";
import localise from "../../../common/localise";

import "./index.scss";

export default class SetupNever extends Component {
  constructor(props) {
    super(props);
    this.state = { selection: 0 };
  }

  onClick(id) {
    this.setState({ selection: parseInt(id, 10) });
  }

  render() {
    const { selection } = this.state;
    const arr = ["btn1", "btn2"];
    const t = "setup.never";

    return (
      <div className="step never">
        <h1>
          <Text text={`${t}.t1`} />
        </h1>
        <section className="buttons" aria-label="region">
          {arr.map((x, i) => (
            <button
              key={i}
              id={i + 1}
              className={` btn radio-btn ${
                selection === i + 1 ? "active" : ""
              }`}
              onClick={this.onClick.bind(this, i + 1)}
              aria-label={localise.getString(t + "." + x)}
            >
              <Text text={`${t}.${x}`} />
            </button>
          ))}
        </section>
        <section className="bottom" aria-label="region">
          {selection ? (
            <article>
              <article className="help-text-container">
                <div>
                  <img
                    src={config.getImage("Mona-standard.svg")}
                    className="icon mona-lisa "
                    alt="mona info"
                  />
                </div>
                {selection === 1 ? (
                  <Text className="help-text" text={`${t}.t2`} />
                ) : (
                  <Text className="help-text" text={`${t}.t3`} />
                )}
              </article>
              {selection === 1 ? (
                <NextButton
                  label={t + ".nextButton"}
                  link={localise.getString("appSettings.neverTellMeMore")}
                ></NextButton>
              ) : (
                <NextButton
                  label={t + ".nextButton"}
                  link={localise.getString("appSettings.neverTellMeMore2")}
                ></NextButton>
              )}
            </article>
          ) : null}
        </section>
      </div>
    );
  }
}
