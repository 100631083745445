import { registerLang, setCurrentLocale } from "react-easy-i18n";

import en from "./lang/en"; // english
import enAU from "./lang/en-au"; // au english 
import enNZ from "./lang/en-au"; // nz english (currently the same as en-au)
import enUK from "./lang/en-uk"; // uk english 
import enUS from "./lang/en-us"; // us english
import de from "./lang/de"; // german
import es from "./lang/es"; // spanish
import ru from "./lang/ru"; // russian
import ar from "./lang/ar"; // arabic
import pt from "./lang/pt"; // portugues
import cs from "./lang/cs"; // czeck
import da from "./lang/da"; // danish
import fi from "./lang/fi"; // danish
import fr from "./lang/fr"; // french
import hu from "./lang/hu"; // hungarian
import he from "./lang/he"; // hebrew
import sv from "./lang/sv"; // swedish
import tk from "./lang/tk"; // turkish
import hi from "./lang/hi"; // hindi
import zh from "./lang/zh"; // chinese
import ja from "./lang/ja"; // japanese
import id from "./lang/id"; // indonesian
import ml from "./lang/ml"; // malay
import nl from "./lang/nl"; // netherlands
import ro from "./lang/ro"; // romanian
import ur from "./lang/ur"; // urdu
import th from "./lang/th"; // thai
import vi from "./lang/vi"; // vietnamese
import it from "./lang/it"; // italian

// localization initialization using react-easy-i18n
// https://www.npmjs.com/package/react-easy-i18n

export default class localise {
  static data;
  static code;
  static rtl = false; // right left : true for hebrew and arabic

  static getString(id) {
    // get raw text from language data

    let arr = id.split(".");
    let x = localise.data;

    for (let i = 0; i < arr.length; i++) {
      if (x) x = x[arr[i]];
    }

    // if not found return id

    if (!x) {
      console.log("localise.getString " + id + " not found");
      x = id;
    }

    return x;

    // process call cause problems in the testing
    //return process(id)
  }

  static getfirst(id) {
    // getfirst used in dropdown fields to obtain first value in array
    // Ternary used to also support key:value pairs in arrays
    return typeof id[0].value === "undefined" ? id[0] : id[0].value;

    // process call cause problems in the testing
    //return process(id)
  }


  static countryCodeToLangCode(country) {
    // map cochlear country code to 2 character language id

    let res = { data: en, code: "en" }; // default

    // main cochlear website supported countries
    // we only handle these codes

    // au: australia new zealand
    // at: austria
    // cz: czech republic
    // dk: denmark
    // fi: finland
    // fr: france
    // de: germany
    // hu: hungary
    // il: israel
    // it: italy
    // nl: netherlands
    // ro: romainia
    // es: spain
    // sv: sweden
    // ch: switzerland
    // tr: turkey
    // uk: united kingdom

    // sg: singapore
    // in: india
    // cn: china
    // jp: japan
    // hk: hong kong/taiwan
    // id: indonesia
    // my: malaysia
    // pk: pakistan
    // ph: phillipines
    // lk: sri lanka
    // th: thailand
    // vn: vietnam

    // la: latin america
    // br: brazil
    // me: middle east
    // uk/africa: uk and africa
    // ru: russia
    // us: united states

    switch (country) {
      case "au":
				res = { data: enAU, code: "en-au" };
				break;
      case "nz":
				res = { data: enNZ, code: "en-nz" };
				break;
      case "uk/africa":
      case "intl":
      case "ph":
      case "lk":
      default:
        // english
        res = { data: en, code: "en" };
        break;

      case "uk":
      case "uk/en":
        res = { data: enUK, code: "en-uk" };
        break;

      case "us":
      case "us/en":
        res = { data: enUS, code: "en-us" };
        break;

      case "de": // germany
      case "at": // austria
      case "ch": // switzerland
        res = { data: de, code: "de" };
        break;

      case "es":
      case "la":
        res = { data: es, code: "es" };
        break; // spanish

      case "sg":
      case "my":
        res = { data: ml, code: "ml" };
        break; // malaysian

      case "cn":
      case "hk":
        res = { data: zh, code: "zh" };
        break; // chinese

      case "dk":
        res = { data: da, code: "da" };
        break; // denmark
      case "fi":
        res = { data: fi, code: "fi" };
        break; // finland
      case "fr":
        res = { data: fr, code: "fr" };
        break; // france
      case "hu":
        res = { data: hu, code: "hu" };
        break; // hungary

      case "il": // israel, right to left
        localise.rtl = true;
        res = { data: he, code: "he" };
        break;

      case "me": // arabic, right to left
        localise.rtl = true;
        res = { data: ar, code: "ar" };
        break;

      case "pk": // pakistan, right to left
        localise.rtl = true;
        res = { data: ur, code: "ur" };
        break;

      case "it":
        res = { data: it, code: "it" };
        break; // italy
      case "nl":
        res = { data: nl, code: "nl" };
        break; // netherlands
      case "ro":
        res = { data: ro, code: "ro" };
        break; // romania
      case "sv":
        res = { data: sv, code: "sv" };
        break; // sweden
      case "tk":
        res = { data: tk, code: "tk" };
        break; // turkish
      case "in":
        res = { data: hi, code: "hi" };
        break; // india
      case "jp":
        res = { data: ja, code: "ja" };
        break; // japanese
      case "id":
        res = { data: id, code: "id" };
        break; // indonesian
      case "th":
        res = { data: th, code: "th" };
        break; // thailand
      case "vn":
        res = { data: vi, code: "vi" };
        break; // vietnamese
      case "ru":
        res = { data: ru, code: "ru" };
        break; // russian
      case "br":
        res = { data: pt, code: "pt" };
        break; // portuguese
      case "cz":
        res = { data: cs, code: "cs" };
        break; // czech
      // add more languages here
    }

    return res;
  }

  static init(country) {
    // call once at start of app
    let res = localise.countryCodeToLangCode(country);
    localise.data = res.data;
    localise.code = res.code;
    registerLang("en", localise.data);
    // registerLang('de', de);
    // registerLang('es', es);
    // registerLang('ml', ml);
    // registerLang('zh', zh);
    // registerLang('da', da);
    // registerLang('fi', fi);
    // registerLang('fr', fr);
    // registerLang('hu', hu);
    // registerLang('he', he);
    // registerLang('ar', ar);
    // registerLang('it', it);
    // registerLang('nl', nl);
    // registerLang('ro', ro);
    // registerLang('sv', sv);
    // registerLang('tk', tk);
    // registerLang('hi', hi);
    // registerLang('ja', ja);
    // registerLang('id', id);
    // registerLang('ur', ur);
    // registerLang('th', th);
    // registerLang('vi', vi);
    // registerLang('ru', ru);
    // registerLang('cs', cs);

    if (localise.data) setCurrentLocale(localise.data);
  }
}
