import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import config from '../../../common/config'
import NextButton from '../../nextButton/nextButton'
import PropTypes from 'prop-types'
import localise from '../../../common/localise';

import './index.scss';

export default class SetupHowOften extends Component {

  constructor(props) {

    super(props)

    this.state = {selection: '' }
  }

  onNext() {

    const { selection } = this.state

    if (selection && this.props.onNext)
      this.props.onNext(selection)
  }

  onClick(id) {

    this.setState({selection: id})
  }

  render() {

    const { selection } = this.state

    const arr = ['Always','Sometimes','Never']

    const t = 'setup.howOften'

    let helpText = null

    if (selection === 'Always') 
      helpText = t + '.t3'
    else 
      if (selection === 'Sometimes') 
        helpText = t + '.t4'
        // autoFocus={i === 0}
        return (
          <div className="step how-often">
          <p  className="no-margin"><Text text={`${t}.t1`} /></p>
          <h1><Text text={`${t}.t2`} /></h1>
          <div className='content'>
          <section aria-label='buttons'>
          <article className='buttons'>
          {
            arr.map((x,i) => 

            <button key={i} 
            id={(i+1)}  
            className={`btn radio-btn ${ selection === x ? "active" : ""}`} 
            onClick={this.onClick.bind(this, x)}
            aria-label={localise.getString(t + "." + x)}>

            <Text text={`${t}.${x}`} />
            </button>
            )
          }
          </article>
          </section>
          </div>
          <section className='bottom' aria-label='bottom section'>
          <article>
          {
            helpText ? 
            <article className='help-text-container'>
            <div>
            <img src={config.getImage('Mona-standard.svg')} className='icon mona-lisa ' alt='mona info' />
            </div>
            <Text className='help-text' text={helpText} />
            </article>
            :
            null
          }
          {
            selection ?
            <NextButton label={t + '.nextButton'} onClick={this.onNext.bind(this)}></NextButton>
            :
            null
          }
          </article>
          </section>
          </div>
        )
      }
    }
    SetupHowOften.propTypes = {
      onNext: PropTypes.func
    }