import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import NextButton from '../../nextButton/nextButton'
import config from '../../../common/config'
import PropTypes from 'prop-types'

import './intro1.scss';

export default class PracticeIntro1 extends Component {

  onNext() {

    if (this.props.onNext)
      this.props.onNext()
  }

  render() {

    const t = 'practice.intro1'

    const buttons = ['t5','t6','t7']

    return (

      <div className='step intro1'>

      <section className="panel" aria-label='region'>
      
      <section className='top' aria-label='region'>
      
      <p><Text className='t1' text={`${t}.t1`} /></p>

      <div>
      <img src={config.getImage('Mona-standard.svg')} className='icon mona-lisa ' alt='mona info' />
      </div>
      
      <h2 className="mar-b-30 mar-t-10"><Text className='t2' text={`${t}.t2`} /></h2>

      <p><Text className='t3' text={`${t}.t3`} /><br/><Text className='t4 bold' text={`${t}.t4`} /></p>

      </section>

      </section>    

      <section className='bottom' aria-label='region'>

      {
        buttons.map(x => 
        <article key={x} className='panel panel-border'>
        <p><Text text={`${t}.${x}`} /></p>
        </article>
        )
      }

      </section>

    {/* autoFocus={true} */}

    <NextButton label={t + ".nextButton"} onClick={this.onNext.bind(this)}></NextButton>

    </div>
    )
  }
}

PracticeIntro1.propTypes = {
  onNext: PropTypes.func
}
