import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import config from '../../common/config'
import branding from '../../common/branding'
import localise from '../../common/localise'
import NextButton from '../nextButton/nextButton'
import PropTypes from 'prop-types'

import './begin.scss';

export default class Begin extends Component {

  onNext() {

    if (this.props.onNext)
      this.props.onNext()
  }

  getTimeOfDay() {
    var myDate = new Date()
    var hrs = myDate.getHours()
    let greet
    
    if (hrs < 12)
      greet = 'goodMorning'
    else if (hrs >= 12 && hrs <= 17)
      greet = 'goodAfternoon'
    else if (hrs >= 17 && hrs <= 24)
      greet = 'goodEvening'
    return greet
  }

  render() {

    const t = 'begin'
    const buttons = [{id: 'btn1', description: 'duration', image: 'Icon_clock.svg' }, 
    {id: 'btn2', description: 'for your home computer', image: 'Icon_devices.svg' }, 
    {id: 'btn3', description: 'no beeps', image: 'Icon_speech.svg'}]
    const timeOfDay = this.getTimeOfDay()     
    const brand = branding[config.brandId]
    const footnoteText = localise.getString(t + '.subnote')
    const footnoteText1 = localise.getString(t + '.subnote1')
    const footnoteText2 = localise.getString(t + '.subnote2')

    return (
      <div className="begin" role="main">
      <section className='panel top'>
      <article className='narrow-content'>
      <div>
      <img src={config.getImage('Mona-standard.svg')} className='icon mona-lisa ' alt='mona info' />
      </div>
      <h1> <Text className="goodMorning" text={`begin.${timeOfDay}`} /></h1>
      </article>
      <article className='narrow-content'>
      <p><Text text={`${t}.t1`} /></p>
      </article>
      </section>
      <h2><Text text="begin.about" /></h2>
      <section className='bottom'>
      {
        buttons.map(x => 
        <article key={x.id} className='panel panel-border'>
        <img src={config.getImage(x.image)} alt={x.description} />
        <p><Text text={`${t}.${x.id}`} /></p>
        </article>
        )
      }
      </section>
    {/* autoFocus={true} */}
    <NextButton label={t + ".nextButton"} onClick={this.onNext.bind(this)}></NextButton>
    <article className='footnote'>
    <span>
    {footnoteText}
    <a className="btn btn-link" href={brand.nal}
    role="button"
    target="_blank" 
    rel="noopener noreferrer" 
    title={ localise.getString(`${t}.findOutMore`) }
    ><Text text={`${t}.findOutMore`} /></a><br/>
    {footnoteText1}<br/>
    {footnoteText2}
    </span>
    </article>
    </div>
    );
  }
}

Begin.propTypes = {
  onNext: PropTypes.func
}
