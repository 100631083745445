import React, { Component } from 'react';
import { Text } from 'react-easy-i18n';
import NextButton from '../../nextButton/nextButton'
import PropTypes from 'prop-types'

import './help.scss';

export default class PracticeHelp extends Component {

  onNext() {

    if (this.props.onNext)
      this.props.onNext()
  }

  render() {

    const t = 'practice.help'

    return (

      <div className="step help">
      
      <h2 className="mar-b-30"><Text className='t1' text={`${t}.t1`}/></h2>
      
      <p><Text className='t2' text={`${t}.t2`} /></p>
      
      <p><Text className='t2 bold' text={`${t}.t3`} /><br/></p>

      <p className="mar-b-30"><Text className='t2' text={`${t}.t4`} />
      <br/>
      <Text className='t2' text={`${t}.t5`}/>
      <br/>
      <Text className='t5' text={`${t}.t6`}/>
      </p>

      <NextButton label={t + ".nextButton"} onClick={this.onNext.bind(this)}></NextButton>

      </div>
      )
    }
  }

  PracticeHelp.propTypes = {
    onNext: PropTypes.func
  }
