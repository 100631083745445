import React, { Component } from 'react';

import SetupEars from './ears'
import SetupYears from './years'
import SetupHowOften from './howOften'
import SetupConfirmComfort from './confirmComfort'
import SetupVolume from './volume'
import SetupForm from './form'
import SetupNever from './never'
import SetupAgeRange from './ageRange'

import PropTypes from 'prop-types'
import { Text } from 'react-easy-i18n'

import './setup.scss';

export default class Setup extends Component {

  constructor(props) {

    super(props)

    this.state = {step: 'howManyEars', data: props.data}
  }

  onNext(res) {

    let { step, data } = this.state

    if (!data)
      data = {}

    switch (step) {
      case 'howManyEars': 
      default:

            data.howManyEars = res
            step = 'howManyYears'
            break;

      case 'howManyYears': 

            data.yearsOfHearingLoss = res
            step = 'howOften'
            break;

      case 'howOften': 

            data.hearingAidsHowOften = res; 
            
            step = (res === 'Never') ? 'never' : 'ageRange'
          
            break;

      case 'ageRange': 

            data.ageRange = res; 
            step = 'confirmComfort'
          
            break;

      case 'confirmComfort': 

            step = 'volume'
            break;     
                                        
      case 'volume': 
      
            data.audioLevelPercentage = res; 
            step = 'form'
            break;

      case 'never':

            step = 'finished'
            break;

      case 'form': 

            data.firstName = res.firstName;
            data.lastName = res.lastName;
            data.email = res.email;
            data.Phone = res.Phone;
            data.Phonetype = res.Phonetype;
            data.postcode = res.postcode;
            data.country = res.country;
            data.bestDescription = res.bestDescription;
            data.marketingConsent = res.marketingConsent;
            step = 'finished'
            break;
    }

    if (step === 'finished' && this.props.onNext ) {

      // finished

        this.props.onNext(data)
    }
    else {

      // move on to next step

      this.setState({step, data})

      // if (this.props.onProgress)
      //   this.props.onProgress(step * (1 / this.MAX_STEPS))
    }
  }

  onBack() {

    let { step } = this.state

    switch (step) {
      case 'howManyEars': 
      default:

            break;

      case 'howManyYears':    step = 'howManyEars'; break;
      case 'howOften':        step = 'howManyYears'; break;
      case 'ageRange':        step = 'howOften';    break;
      case 'confirmComfort':  step = 'ageRange'; break;                                    
      case 'volume':          step = 'confirmComfort'; break;
      case 'never':           step = 'howOften'; break;
      case 'form':            step = 'volume'; break;
    }
    
    this.setState({step})
  }

  render() {

    const { setup } = this.props
    
    const { step, data } = this.state

    // pick out the fields we will show on the form

    let formData = {
      firstName: '',
      lastName: '',
      email: '',
      postcode: '',
      country: '',
      bestDescription: '',
      marketingConsent: '',
    }

    if (data) {

      formData.firstName = data.firstName
      formData.lastName = data.lastName
      formData.email = data.email
      formData.postcode = data.postcode
      formData.country = data.country
      formData.Phone = data.Phone
      formData.Phonetype = data.Phonetype 
      formData.bestDescription = data.bestDescription 
      formData.marketingConsent = data.marketingConsent 
    }


    let content

    let classNames = ['setup']

    switch (step) {
      case 'howManyEars': 
      default:
         content = <SetupEars data={data} onNext={this.onNext.bind(this)} />; 
         break;
      case 'howManyYears': 
          content = <SetupYears data={data} onNext={this.onNext.bind(this)} />; 
          classNames.push('grow-vertical')
          break;
      case 'howOften': content = <SetupHowOften data={data} onNext={this.onNext.bind(this)} />; break;
      case 'confirmComfort': content = <SetupConfirmComfort data={data} onNext={this.onNext.bind(this)} />; break;
      case 'volume': content = <SetupVolume data={data} setup={setup} onNext={this.onNext.bind(this)} />; break;
      case 'form': content = <SetupForm data={formData} onNext={this.onNext.bind(this)} />; break;
      case 'never': content = <SetupNever data={formData} onNext={this.onNext.bind(this)} />; break;
      case 'ageRange': content = <SetupAgeRange data={formData} onNext={this.onNext.bind(this)} />; break;
    }

    return (
      <div className="panel" role="main">

        {
          step !== 'howManyEars' ?
              <button className='btn btn-link btn-back' onClick={this.onBack.bind(this)}>
                <Text text='setup.back' /> 
              </button>
              :
              null
        }


        <div className={classNames.join(' ')}>      
          { content }
        </div>
      </div>
    );
  }
}

Setup.propTypes = {
  data: PropTypes.object,
  setup: PropTypes.object,
  onNext: PropTypes.func
}