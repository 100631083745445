import React, { Component } from 'react';
import HomeIntro from './intro/intro'
import PropTypes from 'prop-types'

import './home.scss';

export default class Home extends Component {

  onNext(val) {

    if (this.props.onNext)
      this.props.onNext('Australia')
    
  }

  render() {

    return (

      <div className="home" role="main">
      <HomeIntro onNext={this.onNext.bind(this) } />
      </div>
      );
  }
}

Home.propTypes = {
  onNext: PropTypes.func
}