import React, { Component } from "react";
import config from "../../common/config";
import branding from "../../common/branding";
import localise from "../../common/localise";
import PropTypes from "prop-types";

import "./header.scss";

export default class Header extends Component {
  onClick(id) {
    if (this.props.onHeaderClick) this.props.onHeaderClick(id);
  }

  cochlearHeader() {
    const brand = branding[config.brandId];

    const logo = config.getImage(brand.logo);

    const mobilelogo = config.getImage(brand.mobilelogo);

    return (
      <div className="cochlear-header">
        <div className="desktop-header">
          <a
            href={localise.getString("appSettings.cochlearHome")}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="text-left" src={logo} alt="logo" />
          </a>
        </div>

        <div className="mobile-header">
          <a
            href={localise.getString("appSettings.cochlearHome")}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="text-left" src={mobilelogo} alt="logo" />
          </a>
        </div>

        {config.devMode ? (
          <div className="hide-desktop">
            <button
              type="button"
              className="btn  text-right"
              onClick={this.onClick.bind(this, "practice")}
            >
              Practice
            </button>
            <button
              type="button"
              className="btn  text-right"
              onClick={this.onClick.bind(this, "test")}
            >
              Test
            </button>
            <button
              type="button"
              className="btn  text-right"
              onClick={this.onClick.bind(this, "unsure")}
            >
              Unsure
            </button>
            <button
              type="button"
              className="btn  text-right"
              onClick={this.onClick.bind(this, "strong candidate")}
            >
              Strong Candidate
            </button>
            <button
              type="button"
              className="btn  text-right"
              onClick={this.onClick.bind(this, "likely candidate")}
            >
              Likely Candidate
            </button>
            <button
              type="button"
              className="btn  text-right"
              onClick={this.onClick.bind(this, "possible candidate")}
            >
              Possible Candidate
            </button>
            <button
              type="button"
              className="btn  text-right"
              onClick={this.onClick.bind(this, "not a candidate")}
            >
              Not a Candidate
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const content =
      config.brandId === "cochlear" ? this.cochlearHeader() : null;

    return <header className="app-header row">{content}</header>;
  }
}

Header.propTypes = {
  onHeaderClick: PropTypes.func
};
